import React from 'react'
import { Icon } from 'semantic-ui-react'
import PropTypes from 'prop-types'
import './icon-button.css'

const IconButton = props => {
    const iconButtonClass = props.isMobile ? 'icon-btn-text hide-in-mobile' : 'icon-btn-text'
    const margin = props.isMobile ? '0.05em 0.063em 0.38em 0' : null

    return (
        <>
            {props.isDisabled ?
                <div className='disabled-btn icon-btn'>
                    <Icon
                        name={props.icon}
                        style={{ margin: margin }} />
                    <div className={iconButtonClass}>{props.text}</div>
                </div> :
                <div
                    className={`${props.className} icon-btn`}
                    onClick={props.onClick}>
                    <Icon
                        name={props.icon}
                        style={{ margin: margin }} />
                    {props.text && <div className={iconButtonClass}>{props.text}</div>}
                </div>
            }
        </>
    )
}

IconButton.defaultProps = {
    isDisabled: false,
    isMobile: true,
    onClick: null,
}

IconButton.propTypes = {
    className: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    isDisabled: PropTypes.bool,
    isMobile: PropTypes.bool,
    onClick: PropTypes.func,
    text: PropTypes.string.isRequired,
}

export default IconButton
