const invertSortDirection = direction => direction === 'ascending' ? 'descending' : 'ascending'

export function handleClearClick(setFilter) {
    return () => setFilter('')
}

export const sortColumnClick = (column, sort, setSort) => () => {
    if (!sort.column || sort.column !== column.valueName) { // Different column was clicked
        setSort({ column: column.valueName, direction: sort.direction })
    } else { // Same column was clicked
        setSort({ ...sort, direction: invertSortDirection(sort.direction) })
    }
}

export const handleSearchInputChange = setFilter => e => {
    setFilter(e.target.value)
}

export const handleOpenClick = (onEdit, item) => () => {
    onEdit(item)
}

export const handleContent = (currentPage, item, column) => {
    if (currentPage !== 'users')
        return item[column.valueName]

    return `${item.lastname} ${item.firstname} (${item.email})`
}
