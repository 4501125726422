const invertSortDirection = direction => direction === 'ascending' ? 'descending' : 'ascending'

export const handleObserver = setPage => entities => {
    const target = entities[0]

    setPage(target.isIntersecting)
}

export const handleSorting = (setIsSorting, bool) => setIsSorting(bool)

export const isBilled = data => data === 'yes'

export const sortColumnClick = (column, sort, setSort, setIsSorting) => () => {
    setIsSorting(true)

    if (!sort.column || sort.column !== column.valueName) { // Different column was clicked
        setSort({ column: column.valueName, direction: sort.direction })
    } else { // Same column was clicked
        setSort({ ...sort, direction: invertSortDirection(sort.direction) })
    }
}

export const handleActiveRow = (setSelectedRow, item) => () => {
    setSelectedRow(item)
}
