import { reportAction } from '../../../reducers/reports-reducer/reports-reducer'
import { reports, reportInfiniteScroll } from '../../../utils/redux-dispatch-configurations'

let filteredCustomer

export const handleAmountChange = setReportAmount => (_e, { value }) => setReportAmount(value)
export const setNewLimitStart = (setLimitStart, limitStart) => difference => setLimitStart(limitStart + difference)

export const handleSortOrderChange = setSortOrder => value => {
    setSortOrder(value)
}

export const handleDisplayChange = (setSelectDisplay, setTableIsShown) => e => {
    setSelectDisplay(e)
    setTableIsShown(false)
}

export const getWebView = ({
    customerForm,
    selectDisplay,
    reportIsLoading,
    dispatch,
    sortOrder,
    reportAmount,
    limit,
    firstDate,
    secondDate,
    setLimitStart,
    setTableIsShown,
}) => () => {
    const formData = new FormData(customerForm.current)
    const { customer } = Object.fromEntries(formData)

    filteredCustomer = customer

    if (!reportIsLoading) {
        let reportActionBody = {
            report_type: 'webview_report',
            sort_order: sortOrder,
            customer_name: filteredCustomer,
        }

        if (selectDisplay === 'optionTime') {
            reportActionBody = {
                ...reportActionBody,
                amount: false,
                limit_start: 0,
                limit: limit,
                from_date: firstDate,
                to_date: secondDate,
            }

            setLimitStart(limit)
        } else {
            reportActionBody = {
                ...reportActionBody,
                limit: reportAmount,
                amount: true,
            }
        }

        dispatch(reportAction(reportActionBody, reports))
    }

    setTableIsShown(true)
}

export const handleDateChange = setDate => date => setDate(date)

export const keyPressHandler = webViewFunction => e => {
    if (e.key === 'Enter') {
        e.preventDefault()
        webViewFunction()
    }
}

export const getMoreOrders = ({
    infiniteScrollingIsLoading,
    dispatch,
    sortOrder,
    limitStart,
    limit,
    firstDate,
    secondDate,
}) => () => {
    if (!infiniteScrollingIsLoading) {
        dispatch(reportAction({
            report_type: 'webview_report',
            sort_order: sortOrder,
            amount: false,
            limit_start: limitStart,
            limit: limit,
            from_date: firstDate,
            to_date: secondDate,
            customer_name: filteredCustomer,
        }, reportInfiniteScroll))
    }
}
