import React, { useState } from 'react'
import Calendar from 'react-calendar'
import { Modal, Button } from 'semantic-ui-react'
import PropTypes from 'prop-types'
import {
    handleOpenModal,
    handleChange,
    formatDate,
} from './date-interval.functions'

const DateInterval = props => {
    const [openFirst, setOpenFirst] = useState(false)
    const [openSecond, setOpenSecond] = useState(false)

    return (
        <>
            <div className='reports-selector-box'>
                <h4>Mistä</h4>
                <Button
                    className='orange-white-btn'
                    content='Valitse'
                    data-testid='fromDate'
                    disabled={props.isDisabled}
                    icon='calendar'
                    onClick={handleOpenModal(setOpenFirst, true)} />
                <strong
                    className='from-date'
                    data-testid='fromDateValue'
                >
                    {formatDate(props.firstDate)}
                </strong>
            </div>
            <div className='reports-selector-box'>
                <h4>Mihin</h4>
                <Button
                    className='orange-white-btn'
                    content='Valitse'
                    data-testid='toDate'
                    disabled={props.isDisabled}
                    icon='calendar'
                    onClick={handleOpenModal(setOpenSecond, true)} />
                <strong
                    className='to-date'
                    data-testid='toDateValue'
                >
                    {formatDate(props.secondDate)}
                </strong>
            </div>
            <Modal
                className='date-modal'
                data-testid='fromDateModal'
                dimmer='inverted'
                onClose={handleOpenModal(setOpenFirst, false)}
                open={openFirst}
            >
                <Calendar
                    locale='fi-FI'
                    onChange={handleChange(props.setFromDate, setOpenFirst)}
                    value={props.firstDate}
                />
            </Modal>
            <Modal
                className='date-modal'
                data-testid='toDateModal'
                dimmer='inverted'
                onClose={handleOpenModal(setOpenSecond, false)}
                open={openSecond}
            >
                <Calendar
                    locale='fi-FI'
                    onChange={handleChange(props.setToDate, setOpenSecond)}
                    value={props.secondDate}
                />
            </Modal>
        </>
    )
}

DateInterval.defaultProps = { isDisabled: false }

DateInterval.propTypes = {
    firstDate: PropTypes.instanceOf(Date).isRequired,
    isDisabled: PropTypes.bool,
    secondDate: PropTypes.instanceOf(Date).isRequired,
    setFromDate: PropTypes.func.isRequired,
    setToDate: PropTypes.func.isRequired,
}

export default DateInterval
