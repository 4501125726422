import React, { useState } from 'react'
import { Button } from 'semantic-ui-react'
import PropTypes from 'prop-types'
import { handleButtonToggle, handleAddConditionClick } from './multi-report-conditions.functions'
import './multi-report-conditions.css'

const MultiReportConditions = props => {
    const [open, setOpen] = useState(false)

    const filteredConditionOptions = props.conditionOptions
        .filter((_conditionOption, index) =>
            props.conditionalMatrix[index][props.currentTypeIndex])
        .filter(option => !props.conditions.find(condition => condition.key === option))
        .map(addableConditionalOption =>
            <Button
                className='orange-white-btn'
                content={addableConditionalOption}
                key={addableConditionalOption}
                onClick={handleAddConditionClick(props.addConditions, addableConditionalOption)}
            />)

    if (open) {
        return (
            <>
                <Button
                    className='orange-white-btn'
                    content='Piilota'
                    icon='delete'
                    onClick={handleButtonToggle(setOpen, false)} />
                <div className='reports-add-conditions-list'>
                    {filteredConditionOptions}
                </div>
            </>
        )
    }

    return (
        <Button
            className='orange-white-btn'
            content='Lisää rajausehto'
            icon='plus'
            onClick={handleButtonToggle(setOpen, true)} />
    )
}

MultiReportConditions.propTypes = {
    addConditions: PropTypes.func.isRequired,
    conditionalMatrix: PropTypes.array.isRequired,
    conditionOptions: PropTypes.array.isRequired,
    conditions: PropTypes.array.isRequired,
    currentTypeIndex: PropTypes.number.isRequired,
}

export default MultiReportConditions
