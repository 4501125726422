import { errorAction, expiredTokenAction, successAction } from '../notification-reducer/notification-reducer'
import { lambdaCall } from '../../services/aws-lambda-service/aws-lambda-service'
import { loginAction } from '../login-reducer/login-reducer'
import { logout } from '../../utils/redux-dispatch-configurations'

// ACTION TYPES
const REQUEST = 'USERS-REQUEST'
const FAILURE = 'USERS-FAILURE'
const GET_SUCCESS = 'GET-USERS-SUCCESS'
const CREATE_SUCCESS = 'CREATE-USER-SUCCESS'
const EDIT_SUCCESS = 'EDIT-USER-SUCCESS'
const DELETE_SUCCESS = 'DELETE-USER-SUCCESS'
const initialState = { users: undefined }
const duplicateErrorMessage = 'Email is duplicate'
const legacyUserErrorMessage = 'User has not registered to new Maranet'

// REDUCER
export const usersReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case REQUEST:
            return { ...state, loading: true }
        case FAILURE:
            return { ...state, loading: false, userError: true }
        case GET_SUCCESS:
            return { ...state, loading: false, users: action.payload, status: action.status }
        case CREATE_SUCCESS:
            return { ...state, loading: false, users: [...state.users, action.payload] }
        case EDIT_SUCCESS:
            return {
                ...state,
                loading: false,
                users: state.users.map(user =>
                    user.cognitoId === action.cognitoId ?
                        action.user :
                        user),
            }
        case DELETE_SUCCESS:
            return { ...state, loading: false, users: state.users.filter(user => user.cognitoId !== action.cognitoId) }
        default:
            return state
    }
}

// ACTION CREATORS
export const userAction = (body, config, newUser) => async dispatch => {
    try {
        let userObject

        if (config.request)
            dispatch({ type: config.request })

        const response = await lambdaCall({ body: body, endpoint: config.endpoint, duplicateErrorMessage: duplicateErrorMessage })

        userObject = response

        if (config.dispatchType === GET_SUCCESS) {
            userObject = response.data.users.map(user => ({
                id: user.aspnet_id,
                cognitoId: user.cognito_id,
                email: user.email,
                firstname: user.firstname,
                lastname: user.lastname,
                phone_number: user.phone,
                status: user.status,
                disabled: user.disabled,
                usergroups: response.data.usergroups.filter(ug =>
                    ug.user_id === user.aspnet_id).map(group => group.usergroup_id),
                role: response.data.userroles.filter(ur =>
                    ur.user_id === user.aspnet_id).map(role => role.role_id).toString(),
            }))
        } else if (config.dispatchType === CREATE_SUCCESS) {
            userObject = {
                id: response.data.created_user.aspnet_id,
                cognitoId: response.data.created_user.cognito_id,
                email: response.data.created_user.email,
                firstname: response.data.created_user.firstname,
                lastname: response.data.created_user.lastname,
                phone_number: response.data.created_user.phone,
                usergroups: response.data.users_groups.map(ug => ug.usergroup_id),
                role: response.data.users_roles.map(role => role.RoleId),
            }
        }

        dispatch({
            type: config.dispatchType,
            payload: config.dispatchType === CREATE_SUCCESS ||
                config.dispatchType === GET_SUCCESS ?
                userObject :
                response.data,
            status: response.status,
            cognitoId: body.cognitoId,
            user: newUser,
        })

        config.successText && dispatch(successAction())
    } catch (error) {
        handleErrorCases(error, dispatch, body)
    }
}

const handleErrorCases = (error, dispatch, body) => {
    if (error.message !== 'canceled') {
        dispatch({ type: FAILURE })
        dispatch(errorAction())
    }

    if (error.message === duplicateErrorMessage)
        dispatch(errorAction(`Käyttäjä "${body.email}" on jo olemassa`))

    if (error.message === 'Token expired') {
        dispatch(loginAction({}, logout))
        dispatch(expiredTokenAction())
    }

    if (error.response?.data.message === 'Count exceeds limit')
        dispatch(errorAction(`Käyttäjiä voi olla enintään ${error.response.data.userLimit}`))

    if (error.response?.data.message === legacyUserErrorMessage)
        dispatch(errorAction('Käyttäjä ei ole rekisteröitynyt uuteen palveluun'))
}
