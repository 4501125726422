import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Form, Table } from 'semantic-ui-react'
import { useForm } from '../../hooks'
import { handleSaveClick, sendLinkForPasswordChange } from './personal-information.functions'
import './personal-information.css'
import PropTypes from 'prop-types'

const PersonalInformation = props => {
    const { user } = useSelector(state => state.userData)
    const { loading } = useSelector(state => state.personalInformation)
    const [sendLinkPending, setSendLinkPending] = useState(false)
    const dispatch = useDispatch()

    const initialValues = {
        business: user.business_name,
        firstname: user.user_firstname,
        lastname: user.user_lastname,
        phone_number: user.phone,
    }

    const { values, formErrors, handleChange } = useForm(initialValues)

    const tableRows = [
        {
            title: 'Yritys',
            value: values.business,
        },
        {
            title: 'Nimi',
            value: `${values.firstname} ${values.lastname}`,
        },
        {
            title: 'Vaihda puhelinnumero',
            value: <Form.Input
                autoComplete="tel"
                error={formErrors.phone_number}
                name='phone_number'
                onChange={handleChange}
                placeholder='Puhelinnumero'
                required
                type='tel'
                value={values.phone_number} />,
        },
        {
            title: 'Salasanan vaihto',
            value:
                <div>
                    Salasanan vaihto tapahtuu erillisen <br /> sähköpostiin lähetettävän linkin kautta
                    <br />
                    <Button
                        className='orange-white-btn'
                        loading={sendLinkPending}
                        onClick={sendLinkForPasswordChange(setSendLinkPending, user, dispatch)}
                    >
                        Lähetä sähköposti
                    </Button></div>,
        },
    ]

    return (
        <div className='personal-information-modal'>
            <h2>Omat tiedot</h2>
            <div className='profile'>
                <div
                    className='personal-information-add-values profile-information'
                    id='form-input'>
                    <Table
                        celled
                        padded>
                        <Table.Body>
                            {
                                tableRows.map(row =>
                                    <Table.Row key={row.title}>
                                        <Table.Cell collapsing>
                                            <h4>{row.title}</h4>
                                        </Table.Cell>
                                        <Table.Cell>
                                            {row.value}
                                        </Table.Cell>
                                    </Table.Row>)
                            }
                        </Table.Body>
                    </Table>
                    <div className='flex-center'>
                        <Button
                            className='orange-white-btn'
                            disabled={!!formErrors.phone_number}
                            loading={loading}
                            onClick={handleSaveClick(dispatch, values, user)}>Tallenna</Button>
                        <Button
                            className='orange-white-btn'
                            onClick={props.onClose}>Sulje</Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

PersonalInformation.propTypes = { onClose: PropTypes.func.isRequired }

export default PersonalInformation
