import React, { useEffect, useState } from 'react'
import { Button, Form, Grid, Header, Segment, Divider } from 'semantic-ui-react'
import { useDispatch, useSelector } from 'react-redux'
import { loginAction } from '../../reducers/login-reducer/login-reducer'
import { Link, useNavigate } from 'react-router-dom'
import logoImg from '../../assets/images/logo-444444.png'
import { login } from '../../utils/redux-dispatch-configurations'
import Eula from '../../components/eula/eula'

const Login = () => {
    // Local state
    const [geolocation, setGeolocation] = useState(null)
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { user, loading } = useSelector(state => state.userData)
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        if (!user)
            return

        setIsLoading(!user.eula.eulaAccepted)

        if (user.eula.eulaAccepted)
            navigate('/')
    }, [user, navigate])

    useEffect(() => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(position => {
                setGeolocation(`${position.coords.latitude}, ${position.coords.longitude}`)
            })
        }
    }, [geolocation])

    const onLogin = e => {
        e.preventDefault()

        if (email.length !== 0 && password.length !== 0)
            dispatch(loginAction({ email: email, password: password, location: geolocation }, login))
    }

    return (
        <>
            {loading || user ?
                <Eula
                    isLoading={isLoading}
                    user={user}
                /> :
                <div className="login-bg">
                    <Grid
                        data-testid={'grid'}
                        style={{ height: '100vh' }}
                        textAlign='center'
                        verticalAlign='middle'>
                        <Grid.Column
                            data-testid={'grid-column'}
                            style={{ maxWidth: 450 }}
                        >
                            <Header>
                                <Header.Content>
                                    <img
                                        alt="Maranet logo"
                                        className="login-logo"
                                        src={logoImg} />
                                </Header.Content>
                            </Header>

                            <Form
                                onSubmit={onLogin}
                                size='large'>
                                <Segment padded="very">
                                    <h2>Kirjaudu sisään</h2>
                                    <Form.Input
                                        autoComplete='email'
                                        className="login-spacer"
                                        fluid
                                        icon='mail'
                                        iconPosition='left'
                                        name="email"
                                        onChange={({ target }) => setEmail(target.value)}
                                        placeholder='Email'
                                        required
                                        value={email}
                                    />
                                    <Form.Input
                                        autoComplete='current-password'
                                        className="login-spacer"
                                        fluid
                                        icon='lock'
                                        iconPosition='left'
                                        name="pw"
                                        onChange={({ target }) => setPassword(target.value)}
                                        placeholder='Password'
                                        required
                                        type='password'
                                        value={password}
                                    />
                                    <Button
                                        className="login-btn login-spacer"
                                        fluid
                                        size='large'
                                        type="submit">
                                        Kirjaudu
                                    </Button>
                                    <Divider />
                                    <div className="login-links">
                                        <div>
                                            <div>Oletko vanha käyttäjä? </div>
                                            <Link to="/register">Rekisteröidy uuteen palveluun</Link>
                                        </div>
                                        <div><Link to="/reset">Unohtuiko salasana?</Link></div>
                                    </div>
                                </Segment>
                            </Form>
                        </Grid.Column>
                    </Grid>
                </div>}
        </>
    )
}

export default Login
