import { multiReportAdditionalFieldLabels } from '../../../../text-resources/report-text-resources'

/*
    Columns
    0=Kaikki tyypit
    1=Kuljetus
    2=Kaivuutyö
    3=Tuntiajo
    4=Tarvike
    5=Henkilötyö
*/

const conditionalMatrix = [
    [1, 1, 0, 0, 0, 0], // kesto
    [1, 1, 1, 1, 1, 1], // kommentti
    [1, 0, 0, 0, 0, 0], // lisävaruste
    [1, 1, 0, 0, 0, 0], // noutopaikka
    [1, 1, 1, 1, 0, 0], // työntekijä
    [1, 1, 0, 1, 0, 0], // matka
    [1, 1, 1, 1, 0, 1], // aloitus- ja lopetusaika
]

export const getMultiReportCheckboxes = (typeOptions, checkedCheckboxes) => {
    const returns = []

    typeOptions.forEach((_prefix, index) => {
        returns.push({
            type: typeOptions[index].text,
            labels: null,
            id: index,
            checkedCheckboxes: checkedCheckboxes,
        })

        const fieldLabels = multiReportAdditionalFieldLabels.filter((_label, i) => conditionalMatrix[i][index])

        fieldLabels.unshift('Valitse kaikki')
        returns[index].labels = fieldLabels
    })

    return returns
}

export const handleCheckAllBoxes = (checkedCheckboxes, setCheckboxOptionalValues, setCheckedCheckboxes, setRender, render) => values => {
    if (checkedCheckboxes.includes(0)) {
        setCheckboxOptionalValues(values)
        setCheckedCheckboxes(checkedCheckboxes.fill(1))
    } else {
        setCheckboxOptionalValues([])
        setCheckedCheckboxes(checkedCheckboxes.fill(0))
    }

    setRender(!render)
}

export const handleRenderFunction = (setRender, render) => () => {
    setRender(!render)
}
