import React from 'react'
import { Modal } from 'semantic-ui-react'
import PropTypes from 'prop-types'

const SelectorModal = props =>
    <Modal
        className='selector-modal'
        dimmer='inverted'
        onClose={props.onClose}
        open={props.isActive}
    >
        {props.returnListWithSearch}
    </Modal>

SelectorModal.propTypes = {
    isActive: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    returnListWithSearch: PropTypes.object.isRequired,
}

export default SelectorModal
