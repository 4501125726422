import React from 'react'
import { Table } from 'semantic-ui-react'
import PropTypes from 'prop-types'

const OrderEntryItemRow = props => {
    if (!props.data)
        return null

    return (
        <Table.Row>
            <Table.Cell
                collapsing
                width={1}>
                {props.title}:
            </Table.Cell>
            <Table.Cell width={4}>
                {props.data}
            </Table.Cell>
        </Table.Row>
    )
}

OrderEntryItemRow.defaultProps = { data: null }

OrderEntryItemRow.propTypes = {
    data: PropTypes.node,
    title: PropTypes.string.isRequired,
}

export default OrderEntryItemRow
