const paths = [
    { path: '/kirjaukset', roles: ['1', '2', '3', '4', '6'] },
    { path: 'kaikki-kirjaukset', roles: ['2', '3', '4', '6'] },
    { path: '/kirjaustiedot', roles: ['3', '4', '6'] },
    { path: '/kayttajat', roles: ['4', '6'] },
    { path: '/ryhmat', roles: ['4', '6'] },
    { path: '/raportit', roles: ['5', '6'] },
    { path: 'omat-työksiannot', roles: ['3', '4', '6'] },
    { path: 'kaikki-työksiannot', roles: ['4', '6'] },
]

export const hasAccess = (path, roles) => {
    if (!roles)
        return false

    const currentPath = paths.find(item => item.path === path)

    if (!currentPath)
        return false

    return currentPath.roles.includes(roles[0]) // We don't really support more than one role at this point
}
