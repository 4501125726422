import { errorAction } from '../notification-reducer/notification-reducer'
import storage from '../../utils/storage'
import { lambdaCall } from '../../services/aws-lambda-service/aws-lambda-service'

const LOGIN_REQ = 'LOGIN_REQUEST'
const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
const LOGIN_FAILURE = 'LOGIN_FAILURE'
const LOGOUT = 'LOGOUT'
const EDIT_INFO_SUCCESS = 'EDIT-INFORMATION'
const EULA_SUCCESS = 'ACCEPT-EULA-SUCCESS'
const loginTime = 'login_time'
const initialState = {}

const handlePhoneNumberChange = (state, action) => {
    const setNewPhoneNumberToStore = { ...state.user, phone: action.payload.phone }

    return { ...state, user: setNewPhoneNumberToStore }
}

const handleEulaAccepted = (state, action) => {
    const user = { ...state.user, eula: { currentEulaVersion: action.payload, eulaAccepted: true } }

    return { ...state, user }
}

// REDUCERS
export const loginReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case LOGIN_REQ:
            return { ...state, loading: true, user: null }
        case LOGIN_SUCCESS:
            return { ...state, loading: false, user: action.payload }
        case LOGIN_FAILURE:
            return { ...state, loading: false, user: null }
        case LOGOUT:
            return { ...state, loading: false, user: null }
        case EDIT_INFO_SUCCESS:
            return handlePhoneNumberChange(state, action)
        case EULA_SUCCESS:
            return handleEulaAccepted(state, action)
        default:
            return state
    }
}

// ACTION CREATORS
export const loginAction = (body, config) => async dispatch => {
    try {
        if (config.dispatchType === LOGOUT) {
            storage.logoutUser()
            localStorage.removeItem(loginTime)
            dispatch({ type: config.dispatchType })

            return
        }

        if (config.request)
            dispatch({ type: config.request })

        const user = await lambdaCall({ body: body, endpoint: config.endpoint })

        storage.saveUser(config.setUserToStore(user.data, body))
        localStorage.setItem(loginTime, new Date(Date.now()))

        dispatch({
            type: config.dispatchType,
            payload: config.setUserToStore(user.data, body),
            status: user.status,
        })
    } catch (error) {
        dispatch({ type: LOGIN_FAILURE })

        if (config.request === LOGIN_REQ)
            dispatch(errorAction('Väärä käyttäjätunnus tai salasana!'))
        else
            dispatch(errorAction())
    }
}
