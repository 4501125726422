import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Input, Loader, Dimmer, Form, Button } from 'semantic-ui-react'
import TableWithCheckbox from '../table-with-checkbox/table-with-checkbox'
import TableWithEdit from '../table-with-edit/table-with-edit'
import { groupAction } from '../../reducers/groups-reducer/groups-reducer'
import { getGroups } from '../../utils/redux-dispatch-configurations'
import {
    addChildGroup,
    groupComparer,
    handleCancelClick,
    handleDeleteClick,
    handleNameChange,
    handleSaveClick,
    removeChildGroup,
    setUserAndCustomerGroups,
    handleEditClick,
} from './group-page.functions'
import PropTypes from 'prop-types'

const groupsPage = 'groups'
const fillRequiredText = 'Täytä kaikki vaadittavat tiedot'

const tableColumns = [
    {
        header: 'Ryhmän nimi',
        valueName: 'name',
        hideInMobile: false,
    },
]

const GroupPage = props => {
    const dispatch = useDispatch()
    const { loading, usergroups, customergroups, defaultCustomergroupId, defaultUsergroupId } = useSelector(state => state.groups)
    const [edit, setEdit] = useState(false)
    const [name, setName] = useState('')
    const [groups, setGroups] = useState([])
    const [subgroups, setSubgroups] = useState([])
    const [childGroups, setChildGroups] = useState([])
    const [defaultGroupId, setDefaultGroupId] = useState(null)
    const [subgroupDefaultId, setSubgroupDefaultId] = useState(null)
    const groupPageIsUsers = props.groupname === 'usergroups'
    const hasBothGroupTypes = customergroups && usergroups

    if (!loading && hasBothGroupTypes) {
        setUserAndCustomerGroups({
            groups,
            setGroups,
            usergroups,
            setSubgroups,
            customergroups,
            groupPageIsUsers,
            setDefaultGroupId,
            setChildGroups,
            defaultUsergroupId,
            setSubgroupDefaultId,
            defaultCustomergroupId,
        })

        groups.sort(groupComparer)

        subgroups.sort((firstGroup, secondGroup) =>
            (secondGroup.id === subgroupDefaultId) - (firstGroup.id === subgroupDefaultId) ||
            firstGroup.name.localeCompare(secondGroup.name))
    }

    useEffect(() => {
        (!usergroups || !customergroups) && dispatch(groupAction({}, getGroups))
    }, [usergroups, customergroups, dispatch])

    return (
        <>
            <h2>{props.title}</h2>
            <Dimmer.Dimmable dimmed={loading}>
                <Dimmer
                    active={loading}
                    inverted>
                    <Loader />
                </Dimmer>
                <div className='add-values-container'>
                    <Form className='add-values-form'>
                        {edit ? <h3>Muokkaa tietoja</h3> : <h3>Lisää uusi</h3>}
                        <Form.Field
                            control={Input}
                            key={tableColumns[0].valueName}
                            label={tableColumns[0].header}
                            onChange={handleNameChange(setName)}
                            placeholder='Ryhmän nimi'
                            value={name}
                        />
                        <Form.Field>
                            <TableWithCheckbox
                                add={addChildGroup(setChildGroups)}
                                checked={childGroups}
                                defaultGroupId={subgroupDefaultId}
                                items={subgroups}
                                remove={removeChildGroup(childGroups, setChildGroups)}
                                title={props.groupname === 'usergroups' ?
                                    'Valitse asiakasryhmät' :
                                    'Valitse käyttäjäryhmät'}
                            />
                        </Form.Field>
                        {edit ?
                            <Form.Field>
                                <Button
                                    className='orange-white-btn no-margin'
                                    content='Peruuta'
                                    id='group-page-cancel-button'
                                    onClick={handleCancelClick(setEdit, setName, setChildGroups, subgroupDefaultId)}
                                />
                                <Button
                                    className='orange-white-btn'
                                    content='Poista'
                                    disabled={edit.id === defaultGroupId}
                                    id='group-page-delete-button'
                                    onClick={handleDeleteClick({
                                        edit,
                                        dispatch,
                                        groupAction,
                                        usergroup: groupPageIsUsers,
                                        setEdit,
                                        setName,
                                        setChildGroups,
                                        subgroupDefaultId,
                                    })}
                                />
                                <Button
                                    className='orange-white-btn no-margin-left'
                                    content='Tallenna'
                                    id='group-page-save-button'
                                    onClick={handleSaveClick({
                                        usergroup: groupPageIsUsers,
                                        edit,
                                        dispatch,
                                        groupAction,
                                        childGroups,
                                        setName,
                                        setEdit,
                                        fillRequiredText,
                                        name,
                                        setChildGroups,
                                        subgroupDefaultId,
                                    })}
                                />
                            </Form.Field> :
                            <Form.Field>
                                <Button
                                    className='orange-white-btn no-margin'
                                    content='Lisää uusi'
                                    id='group-page-add-new-button'
                                    onClick={handleSaveClick({
                                        usergroup: groupPageIsUsers,
                                        edit,
                                        dispatch,
                                        groupAction,
                                        childGroups,
                                        setName,
                                        setEdit,
                                        fillRequiredText,
                                        name,
                                        setChildGroups,
                                        subgroupDefaultId,
                                    })}
                                />
                            </Form.Field>}
                    </Form>
                    <div className='scrollable-list'>
                        <TableWithEdit
                            columns={tableColumns}
                            currentPage={groupsPage}
                            defaultGroupId={defaultGroupId}
                            edit={edit}
                            items={groups}
                            onEdit={handleEditClick(setEdit, setName, setChildGroups)}
                        />
                    </div>
                </div>
            </Dimmer.Dimmable>
        </>
    )
}

GroupPage.propTypes = {
    groupname: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
}

export default GroupPage
