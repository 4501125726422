import React, { useState, useRef, useEffect, useCallback } from 'react'
import { Loader, Image, Modal, Divider, Button, Dimmer } from 'semantic-ui-react'
import { useDispatch } from 'react-redux'
import { errorAction, expiredTokenAction } from '../../../reducers/notification-reducer/notification-reducer'
import IconButton from '../../icon-button/icon-button'
import { loginAction } from '../../../reducers/login-reducer/login-reducer'
import { logout } from '../../../utils/redux-dispatch-configurations'
import PropTypes from 'prop-types'
import {
    handleImageUpload,
    handleFileDelete,
    getImageLinks,
    handleModalToggle,
    handleInputRefClick,
    showImage,
    handleImageDownload,
} from './file-selector.functions'
import './file-selector.css'

const bottomPosition = isDivider => ({ bottom: isDivider ? 'calc(3px + 0.3em)' : 'calc(30px + 0.3em)' })

const FileSelector = props => {
    const fileInputRef = useRef(null)
    const [isOpen, setIsOpen] = useState(false)
    const [previewFiles, setPreviewFiles] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const dispatch = useDispatch()
    const { orderId, setOrderId } = props
    const [loadedImageIds, setLoadedImageIds] = useState([])
    const [id, setId] = useState(0)
    const [imageLoading, setImageLoading] = useState(false)

    const throwError = useCallback(error => {
        if (error.message === 'Token expired') {
            dispatch(loginAction({}, logout))
            dispatch(expiredTokenAction())
        } else {
            dispatch(errorAction())
        }
    }, [dispatch])

    const setObjectUrls = useCallback((objects, existingOrderId) => {
        for (const [i] of Object.entries(objects)) {
            previewFiles.push({
                id: objects[i].id,
                url: objects[i].url,
                name: objects[i].name,
                key: objects[i].key,
            })

            if (existingOrderId && !props.currentPage)
                props.selected.push(objects[i].id)
        }
    }, [previewFiles, props.currentPage, props.selected])

    useEffect(() => {
        if (orderId === 0)
            return

        setIsLoading(true)

        getImageLinks(null, orderId, setObjectUrls, throwError, setIsLoading).catch(console.error)

        setId(orderId)
        setOrderId(0)
    }, [setObjectUrls, orderId, setOrderId, throwError])

    const getModalTrigger = () => {
        if (props.currentPage) {
            return <div>
                <Button
                    className='orange-white-btn'
                    content='Näytä liitteet'
                    disabled={isLoading}
                    loading={isLoading}
                />
                <Button
                    className='orange-white-btn'
                    content='Lataa liitteet'
                    disabled={isLoading || !!imageLoading}
                    loading={isLoading || !!imageLoading}
                    onClick={handleImageDownload(setImageLoading, throwError, id, null)}
                />
            </div>
        }

        return <div
            className='removable-item'
            data-testid='modal-trigger'
            style={{ cursor: 'pointer' }}>{`Liitteet (${previewFiles.length})`}</div>
    }

    const getClassName = () => {
        if (props.currentPage)
            return 'map-btn'

        return previewFiles.length ? 'selector-box selector-selected' : 'selector-box'
    }

    return (
        <div
            className={getClassName()}
            data-testid='file-selector-box'
        >
            {!props.currentPage ? <h3>{props.title.toUpperCase()}</h3> : null}
            {previewFiles.length || props.currentPage ?
                <Modal
                    className='selector-modal fileselector-modal-size'
                    dimmer='inverted'
                    onClose={handleModalToggle(setIsOpen, false)}
                    onOpen={handleModalToggle(setIsOpen, true)}
                    open={isOpen}
                    trigger={getModalTrigger()}
                >
                    {previewFiles.map((image, index) => {
                        const isDivider = index + 1 === previewFiles.length

                        return (
                            <div
                                className='file-image-preview'
                                data-testid='file-image-preview'
                                key={image.id}>
                                <Dimmer
                                    active={!loadedImageIds.includes(image.id)}
                                    inverted>
                                    <Loader
                                        active
                                        style={{ position: 'absolute' }} />
                                </Dimmer>
                                <Image
                                    alt='Kuvaa ei voida näyttää'
                                    key={image.id}
                                    onError={showImage(image.id, loadedImageIds, setLoadedImageIds)}
                                    onLoad={showImage(image.id, loadedImageIds, setLoadedImageIds)}
                                    src={image.url}
                                />
                                {loadedImageIds.includes(image.id) ?
                                    <Button
                                        className='file-download-button'
                                        content='Lataa'
                                        id={image.id}
                                        loading={imageLoading === image.id}
                                        onClick={handleImageDownload(setImageLoading, throwError, null, image.id)}
                                        style={bottomPosition(isDivider)}
                                    /> :
                                    null}
                                {!loadedImageIds.includes(image.id) || props.currentPage ?
                                    null :
                                    <Button
                                        className='file-delete-btn'
                                        content='Poista'
                                        data-testid={image.key}
                                        id={image.id}
                                        onClick={handleFileDelete({
                                            index: image.id,
                                            key: image.key,
                                            setPreviewFiles,
                                            previewFiles,
                                            setSelected: props.setSelected,
                                            setIsOpen,
                                            dispatch,
                                            throwError,
                                            setIsLoading,
                                        })}
                                        style={bottomPosition(isDivider)}
                                    />}
                                <Divider
                                    fitted={isDivider}
                                    hidden
                                    id={image.id} />
                            </div>
                        )
                    })}
                    <div className='file-selector-footer'>
                        <Button
                            className='sticky-btn'
                            content='Sulje'
                            onClick={handleModalToggle(setIsOpen, false)}
                        />
                    </div>
                </Modal> :
                <div
                    className='removable-item-placeholder'
                >...</div>
            }
            {!props.currentPage &&
                <div
                    className='column-flex'
                    style={{ justifyContent: 'end' }}>
                    {isLoading ?
                        <Loader
                            active
                            className='fileSelector-loader'
                            inline='centered' /> :
                        <>
                            <IconButton
                                as='label'
                                className='selector-btn'
                                htmlFor='file'
                                icon='plus'
                                onClick={handleInputRefClick(fileInputRef)}
                                text='Valitse'
                            />
                            <input
                                accept='image/*'
                                data-testid='file-selector-attachment-input'
                                hidden
                                onChange={handleImageUpload(
                                    dispatch,
                                    setIsLoading,
                                    props.selected,
                                    setObjectUrls,
                                    throwError,
                                    previewFiles
                                )}
                                ref={fileInputRef}
                                type='file'
                            />
                        </>
                    }
                </div>}
        </div>
    )
}

FileSelector.defaultProps = {
    currentPage: null,
    selected: null,
    setSelected: null,
    title: null,
}

FileSelector.propTypes = {
    currentPage: PropTypes.string,
    orderId: PropTypes.number.isRequired,
    selected: PropTypes.array,
    setOrderId: PropTypes.func.isRequired,
    setSelected: PropTypes.func,
    title: PropTypes.string,
}

export default FileSelector
