import React, { useState } from 'react'
import { Modal } from 'semantic-ui-react'
import IconButton from '../../icon-button/icon-button'
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css'
import { getDate, getDateObject } from '../../../utils/time'
import PropTypes from 'prop-types'
import { handleChange, handleStateChange } from './date-selector.functions'

const DateSelector = props => {
    const [active, setActive] = useState(false)
    const [dateValue, setDateValue] = useState(props.selected?.text || new Date())

    return (
        <div
            className={props.selected?.text ? 'selector-box selector-selected' : 'selector-box'}
            data-testid={'selector-div'}
        >
            <h3>{props.title.toUpperCase()}</h3>
            <div className='column-flex'>
                <Modal
                    className='date-modal'
                    data-testid={'modal'}
                    dimmer='inverted'
                    onClose={handleStateChange(setActive, false)}
                    open={active}
                >
                    <Calendar
                        locale='fi-FI'
                        onChange={handleChange(props.setSelected, setDateValue, setActive, props.name)}
                        value={getDateObject(dateValue)}
                    />
                </Modal>
            </div>

            <div
                className='column-flex'
                data-testid={'dateDiv'}>
                {props.selected?.text ?
                    <div className="removable-item">{getDate(props.selected.text)} </div> :
                    <div className='removable-item-placeholder'>...</div>
                }
                <IconButton
                    className='selector-btn'
                    icon='plus'
                    onClick={handleStateChange(setActive, true)}
                    text='Valitse' />
            </div>
        </div>
    )
}

// Stryker disable all
DateSelector.propTypes = {
    name: PropTypes.string.isRequired,
    selected: PropTypes.object.isRequired,
    setSelected: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
}
// Stryker restore all

export default DateSelector
