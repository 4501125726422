import React, { useRef, useState } from 'react'
import { Input, Icon } from 'semantic-ui-react'
import { handleDeleteClick, handleStateChange } from './customer-input.functions'
import PropTypes from 'prop-types'

const CustomerInput = props => {
    const [state, setState] = useState('')
    const textInput = useRef(null)

    return (
        <Input
            className='webview-input'
            fluid
            icon={<Icon
                link
                name='delete'
                onClick={handleDeleteClick(setState, textInput)} />}
            name={'customer'}
            onChange={handleStateChange(setState)}
            onKeyPress={props.keyPressHandler}
            placeholder='Asiakas...'
            ref={textInput}
            state='focus'
            value={state}
        />
    )
}

CustomerInput.propTypes = { keyPressHandler: PropTypes.func.isRequired }

export default CustomerInput
