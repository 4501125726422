import { createGroup, deleteGroup, editGroup } from '../../utils/redux-dispatch-configurations'
import { errorAction } from '../../reducers/notification-reducer/notification-reducer'

export const groupComparer = (a, b) => {
    const aLower = a.name.toLowerCase()
    const bLower = b.name.toLowerCase()

    if (aLower < bLower)
        return -1

    if (aLower > bLower)
        return 1

    return 0
}

export const setUserAndCustomerGroups = ({
    groups,
    setGroups,
    usergroups,
    setSubgroups,
    customergroups,
    groupPageIsUsers,
    setDefaultGroupId,
    setChildGroups,
    defaultUsergroupId,
    setSubgroupDefaultId,
    defaultCustomergroupId,
}) => {
    if (groupPageIsUsers && groups !== usergroups) {
        setGroups(usergroups)
        setSubgroups(customergroups)
        setDefaultGroupId(defaultUsergroupId)
        setSubgroupDefaultId(defaultCustomergroupId)
        setChildGroups([defaultCustomergroupId])
    }

    if (!groupPageIsUsers && groups !== customergroups) {
        setGroups(customergroups)
        setSubgroups(usergroups)
        setDefaultGroupId(defaultCustomergroupId)
        setSubgroupDefaultId(defaultUsergroupId)
        setChildGroups([defaultUsergroupId])
    }
}

export const handleSaveClick = ({
    usergroup,
    edit,
    dispatch,
    groupAction,
    childGroups,
    setName,
    setEdit,
    fillRequiredText,
    name,
    setChildGroups,
    subgroupDefaultId,
}) => () => {
    const saveBody = { usergroup, name: name, groups: childGroups }

    if (edit) {
        if (name !== '') {
            dispatch(groupAction({ ...saveBody, id: edit.id }, editGroup))
            setName('')
            setEdit(false)
            setChildGroups([subgroupDefaultId])
        } else {
            dispatch(errorAction(fillRequiredText))
        }

        return
    }

    if (name !== '') {
        dispatch(groupAction(saveBody, createGroup))
        setName('')
        setChildGroups([subgroupDefaultId])
    } else {
        dispatch(errorAction(fillRequiredText))
    }
}

export const handleEditClick = (setEdit, setName, setChildGroups) => group => {
    setEdit(group)
    setName(group.name)
    setChildGroups(group.subgroups)
}

export const handleCancelClick = (setEdit, setName, setChildGroups, subgroupDefaultId) => () => {
    setEdit(false)
    setName('')
    setChildGroups([subgroupDefaultId])
}

export const handleDeleteClick = ({
    edit,
    dispatch,
    groupAction,
    usergroup,
    setEdit,
    setName,
    setChildGroups,
    subgroupDefaultId,
}) => () => {
    dispatch(groupAction({ id: edit.id, usergroup }, deleteGroup))
    setEdit(false)
    setName('')
    setChildGroups([subgroupDefaultId])
}

export const handleNameChange = setName => ({ target }) => setName(target.value)

export const addChildGroup = setChildGroups => item => {
    setChildGroups(initial => [...initial, item.id])
}

export const removeChildGroup = (childGroups, setChildGroups) => item => {
    const newGroups = childGroups.filter(group => group !== item.id)

    setChildGroups(newGroups)
}
