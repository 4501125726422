export const handleFirstAddOnClick = (valueUp, name, firstValue, setFirstValue) => () => {
    valueUp(name, 23, 0, 1, firstValue, setFirstValue)
}

export const handleFirstSubtractOnClick = (valueDown, name, firstValue, setFirstValue) => () => {
    valueDown(name, 0, 23, 1, firstValue, setFirstValue)
}

export const handleSecondAddOnClick = (valueUp, name, secondValue, setSecondValue) => () => {
    valueUp(name, 45, 0, 15, secondValue, setSecondValue)
}

export const handleSecondSubtractOnClick = (valueDown, name, secondValue, setSecondValue) => () => {
    valueDown(name, 0, 45, 15, secondValue, setSecondValue)
}
