import React from 'react'
import { Dropdown } from 'semantic-ui-react'
import DateInterval from '../date-interval/date-interval'
import PropTypes from 'prop-types'

const amountDropdownOptions = [
    { key: '25', text: '25', value: 25 },
    { key: '50', text: '50', value: 50 },
    { key: '75', text: '75', value: 75 },
    { key: '100', text: '100', value: 100 },
    { key: '150', text: '150', value: 150 },
    { key: '200', text: '200', value: 200 },
    { key: '300', text: '300', value: 300 },
    { key: '1000', text: '1000', value: 1000 },
]

const AmountOrDateRangeValue = props =>
    props.selectDisplay === 'optionTime' ?
        <div className='selector-grid-reports'>
            <div className='reports-selector-box reports-selector-header'>
                <h4>Aikaväli:</h4>
            </div>
            <DateInterval
                firstDate={props.dateIntervalOptions[0]}
                secondDate={props.dateIntervalOptions[2]}
                setFromDate={props.dateIntervalOptions[1]}
                setToDate={props.dateIntervalOptions[3]}
            />
        </div> :
        <div className='selector-grid-reports'>
            <div className='reports-selector-box reports-selector-header'>
                <h4>Määrä:</h4>
            </div>
            <div className='reports-select'>
                <Dropdown
                    className='webview-input'
                    onChange={props.amountOptions.handleAmountChange}
                    options={amountDropdownOptions}
                    selection
                    value={props.amountOptions.reportAmount}
                />
            </div>
        </div>

AmountOrDateRangeValue.propTypes = {
    amountOptions: PropTypes.object.isRequired,
    dateIntervalOptions: PropTypes.array.isRequired,
    selectDisplay: PropTypes.string.isRequired,
}

export default AmountOrDateRangeValue
