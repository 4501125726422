import React, { useState } from 'react'
import CheckboxOptional from '../../../../components/checkbox-optional/checkbox-optional'
import { getMultiReportCheckboxes, handleCheckAllBoxes, handleRenderFunction } from './multi-report-additional-info-fields.functions'
import PropTypes from 'prop-types'

const MultiReportAdditionalInfoFields = props => {
    const [render, setRender] = useState(false)
    const multiReportCheckboxes = getMultiReportCheckboxes(props.typeOptions, props.checkedCheckboxes)

    return (
        <div className='selector-grid-reports'>
            <div className='reports-selector-box reports-selector-header'>
                <h4>Lisätiedot:</h4>
            </div>
            <div className='reports-checkbox-container'>
                {(multiReportCheckboxes.filter(checkBox => checkBox.type === props.currentType).map(object =>
                    object.labels.map((label, id) =>
                        <ul key={label}>
                            <li className='rptbox'>
                                <CheckboxOptional
                                    checkAll={handleCheckAllBoxes(
                                        props.checkedCheckboxes,
                                        props.setCheckboxOptionalValues,
                                        props.setCheckedCheckboxes,
                                        setRender,
                                        render
                                    )}
                                    checkedCheckboxes={object.checkedCheckboxes}
                                    id={id}
                                    name={label}
                                    optionalValues={props.checkboxOptionalValues}
                                    renderFunction={handleRenderFunction(setRender, render)}
                                    values={object}
                                />
                            </li>
                            <li>
                                <strong className='rpttext'>{label}</strong>
                            </li>
                        </ul>))
                )}
            </div>
        </div>
    )
}

MultiReportAdditionalInfoFields.propTypes = {
    checkboxOptionalValues: PropTypes.array.isRequired,
    checkedCheckboxes: PropTypes.array.isRequired,
    currentType: PropTypes.string.isRequired,
    setCheckboxOptionalValues: PropTypes.func.isRequired,
    setCheckedCheckboxes: PropTypes.func.isRequired,
    typeOptions: PropTypes.array.isRequired,
}

export default MultiReportAdditionalInfoFields
