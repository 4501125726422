import React, { useState, useEffect, useRef } from 'react'
import { Input, Label, Popup, Select } from 'semantic-ui-react'
import PropTypes from 'prop-types'
import { handleKeyUp, handleChange } from './quantity-selector.functions'
import './quantity-selector.css'

const QuantitySelector = props => {
    const [unit, setUnit] = useState(props.selected.unit_id ?
        { unit_id: props.selected.unit_id, unit_text: props.selected.unit_text } :
        { unit_id: props.units[0].id || null, unit_text: props.units[0].text })

    const isFirstRender = useRef(true)
    const [value, setValue] = useState(props.selected?.text ?? '')

    useEffect(() => {
        if (!isFirstRender.current)
            setValue('')
        else
            isFirstRender.current = false
    }, [props.isInitialValues])

    useEffect(() => {
        if (props.name === 'quantity' && props.selectedLoad.unit_id) {
            setUnit({
                unit_id: props.selectedLoad.unit_id, unit_text: props.units
                    .find(item => item.id === props.selectedLoad.unit_id).text,
            })
        }
    }, [props.name, props.selectedLoad, props.units])

    return (
        <div
            className={props.selected?.text ? 'selector-selected selector-box' : 'selector-box'}
            data-testid='main-div'
        >
            <div className='column-flex'>
                <h3>{props.title.toUpperCase()}</h3>
                <div className='quantity-selector'>
                    {props.units.length === 1 ?
                        <Input
                            className='quantity-selector-input'
                            fluid
                            focus
                            onChange={handleChange(props.name, props.setSelected, setValue, setUnit, unit)}
                            onKeyUp={handleKeyUp}
                            placeholder='Syötä'
                            size='large'
                            type='number'
                            value={value}
                        >
                            <input />
                            <Label
                                basic
                                className='quantity-selector-label'
                                content={props.units[0].text} />
                        </Input> :
                        <Popup
                            content={value}
                            position='bottom center'
                            style={{ opacity: value ? 1 : 0 }}
                            trigger={
                                <Input
                                    className='quantity-selector-input'
                                    fluid
                                    focus
                                    onChange={handleChange(
                                        props.name,
                                        props.setSelected,
                                        setValue,
                                        setUnit,
                                        unit,
                                    )}
                                    onKeyUp={handleKeyUp}
                                    placeholder='Syötä'
                                    size='large'
                                    type='number'
                                    value={value}
                                >
                                    <input />
                                    <Select
                                        className='quantity-selector-label'
                                        compact
                                        onChange={handleChange(
                                            props.name,
                                            props.setSelected,
                                            setValue,
                                            setUnit,
                                            unit,
                                        )}
                                        options={props.units.map(item => ({
                                            text: item.text,
                                            value: item.id,
                                        }))}
                                        text={unit.unit_text}
                                        value={unit.unit_id} />
                                </Input>
                            }
                        />
                    }
                </div>
            </div>
        </div>
    )
}

// Stryker disable all
QuantitySelector.propTypes = {
    isInitialValues: PropTypes.bool.isRequired,
    name: PropTypes.string.isRequired,
    selected: PropTypes.object.isRequired,
    selectedLoad: PropTypes.object.isRequired,
    setSelected: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    units: PropTypes.array.isRequired,
}
// Stryker restore all

export default QuantitySelector
