import { errorAction, successAction } from '../../reducers/notification-reducer/notification-reducer'
import { lambdaCall } from '../../services/aws-lambda-service/aws-lambda-service'
import { awsApiEndpoints } from '../../utils/aws-api-endpoints'
import { getStorageInfoJson } from '../../utils/helper-functions'
import { logout } from '../../utils/redux-dispatch-configurations'
import { loginAction } from '../../reducers/login-reducer/login-reducer'

const handleSaveClickAsync = async (
    password,
    dispatch,
    setLoading,
    encodedString,
    setPasswordChanged,
    navigate,
) => {
    setLoading(true)

    try {
        await lambdaCall({
            body: {
                encodedString: encodedString[1],
                password: password.value,
            }, endpoint: awsApiEndpoints.setPassword,

        })

        setPasswordChanged(true)
        dispatch(successAction('Salasana asetettu'))

        if (localStorage.getItem('MARANET_LOGGED_USER')) {
            await lambdaCall({
                body: {
                    email: getStorageInfoJson('email'),
                    refreshToken: getStorageInfoJson('refresh_token'),
                }, endpoint: awsApiEndpoints.revokeRefreshToken,
            })

            dispatch(loginAction({}, logout))
        }

        setTimeout(() => {
            navigate('/login')
        }, 10000)
    } catch (error) {
        console.error(error)
        dispatch(errorAction())
    } finally {
        setLoading(false)
    }
}

export const handleSaveClick = (
    password,
    confirmPassword,
    dispatch,
    setLoading,
    encodedString,
    setPasswordChanged,
    navigate,
) => e => {
    e.preventDefault()

    if (password.value !== confirmPassword.value) {
        dispatch(errorAction('Salasanat eivät täsmää'))

        return
    }

    if (password.error) {
        dispatch(errorAction('Salasana ei ole kelvollinen'))

        return
    }

    handleSaveClickAsync(password, dispatch, setLoading, encodedString, setPasswordChanged, navigate).catch(console.error)
}
