import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { Checkbox, Icon, Loader, Segment } from 'semantic-ui-react'
import {
    CommentSelector,
    DateSelector,
    TimeSelector,
    FileSelector,
    QuantitySelector,
    Selector,
    DurationSelector,
} from '../selectors'
import IconButton from '../icon-button/icon-button'
import { entryItemAction } from '../../reducers/entry-items-reducer/entry-items-reducer'
import { getDate, getTime } from '../../utils/time'
import { useOrderForm } from '../../hooks'
import { orderPageEntryItems } from '../../utils/redux-dispatch-configurations'
import { nullish } from '../../utils/helper-functions'
import './orders-page.css'
import PropTypes from 'prop-types'
import {
    handleOrderAttachmentIds,
    calculateDuration,
    valueUp,
    valueDown,
    whichTimeSelector,
    editOrders,
    saveRecord,
    handleToggleChange,
    getLatestOrder,
    emptyInputs,
} from './orders-page.functions'

const customer = 'customer'
const worksite = 'worksite'
const worker = 'worker'
const vehicle = 'vehicle'
const accessory = 'accessory'
const pickup = 'pickup'
const load = 'load'
const product = 'product'
const quantity = 'quantity'
const duration = 'duration'
const startTime = 'start_time'
const endTime = 'end_time'
const comment = 'comment'
const file = 'file'
const routeLength = 'route_length'
const deliveryDate = 'delivery_date'
const workerString = 'työntekijä'

const OrdersPage = props => {
    const initialValues = {
        [customer]: { text: '' },
        [worksite]: { text: '' },
        [worker]: { text: `${props.user.user_firstname} ${props.user.user_lastname}`, id: props.user.id },
        [vehicle]: { text: '' },
        [accessory]: { text: '' },
        [pickup]: { text: '' },
        [load]: { text: '' },
        [product]: { text: '' },
        [quantity]: { text: '' },
        [duration]: { text: '00:00:00' },
        [startTime]: { text: '00:00:00' },
        [endTime]: { text: '00:00:00' },
        [comment]: { text: '' },
        [file]: { text: '' },
        [routeLength]: { text: '' },
        [deliveryDate]: { text: new Date() },
    }

    const navigate = useNavigate()
    const { values, setValues, handleChange } = useOrderForm(initialValues)
    const [isEditing, setIsEditing] = useState(null)
    const [id, setId] = useState(null)
    const [orderAssignment, setOrderAssignment] = useState(false)
    const entryDate = new Date()
    const [geolocation, setGeolocation] = useState(null)
    const [userInfo, setUserInfo] = useState(props.user)
    const dispatch = useDispatch()
    const { entryItems, loading } = useSelector(state => state.entryItems)
    const urlLocation = useLocation()
    const [isLoading, setIsLoading] = useState(false)
    const [orderAttachmentIds, setOrderAttachmentIds] = useState([])
    const [orderId, setOrderId] = useState(0)
    const [startMinutes, setStartMinutes] = useState(0)
    const [startHours, setStartHours] = useState(0)
    const [endMinutes, setEndMinutes] = useState(0)
    const [endHours, setEndHours] = useState(0)
    const [durationHours, setDurationHours] = useState(0)
    const [durationMinutes, setDurationMinutes] = useState(0)
    const [isInitialEditing, setIsInitialEditing] = useState(false)
    const { copy, edit } = urlLocation.state || {}
    const [isInitialValues, setIsInitialValues] = useState(!(copy || edit))

    if (urlLocation.state && isEditing === null) {
        editOrders({
            urlLocation,
            setOrderId,
            setIsEditing,
            setIsInitialEditing,
            setDurationMinutes,
            setDurationHours,
            setStartMinutes,
            setStartHours,
            setEndMinutes,
            setEndHours,
            setUserInfo,
            setId,
            setGeolocation,
            setOrderAssignment,
            setValues,
        })
    }

    useEffect(() => {
        !entryItems && dispatch(entryItemAction({}, orderPageEntryItems))
    }, [dispatch, entryItems])

    const calculateDurationCallback = useCallback(() => {
        calculateDuration(startHours, endHours, startMinutes, endMinutes, setDurationHours, setDurationMinutes)
    }, [endHours, startHours, startMinutes, endMinutes])

    const keepWithinRangeCallback = useCallback((value, setValue, maxValue) => {
        if (value > maxValue)
            setValue(maxValue)
        else if (value < 0)
            setValue(0)
    }, [])

    return (
        <div>
            <div className='order-desc'>
                <h2>{isEditing ? 'Muokkaa kirjausta' : <>{props.title}</>}</h2>
                <p>{props.mainInfo}</p>
                <div className='order-info'>
                    <div className='order-info-item'>
                        <Icon
                            name='user'
                            size='large' />
                        <div>{nullish(userInfo.user_name, `${userInfo.user_firstname} ${userInfo.user_lastname}`)}</div>
                    </div>
                    <div className='order-info-item'>
                        <Icon
                            name='calendar alternate outline'
                            size='large' />
                        <div>{getDate(entryDate)}</div>

                    </div>
                    <div className='order-info-item'>
                        <Icon
                            name='clock outline'
                            size='large' />
                        <div>{getTime(entryDate)}</div>
                    </div>
                </div>

            </div>

            {!loading && !isLoading && entryItems ?
                <>
                    <div className='order-top-controls'>
                        {props.orderAssignmentIsEnabled && !isEditing &&
                            <Segment
                                className='segment-margin'
                                compact
                            >
                                <Checkbox
                                    checked={orderAssignment}
                                    label='Työksianto'
                                    onChange={handleToggleChange(orderAssignment, setOrderAssignment)}
                                    style={{ padding: '.3em' }}
                                    toggle />
                            </Segment>
                        }

                        <IconButton
                            className='orange-white-btn-get-latest'
                            icon='undo'
                            isMobile={false}
                            onClick={getLatestOrder({
                                typeId: props.typeId,
                                setValues,
                                setIsLoading,
                                initialValues,
                                handleChange,
                                dispatch,
                                setDurationMinutes,
                                setDurationHours,
                                setStartMinutes,
                                setStartHours,
                                setEndMinutes,
                                setEndHours,
                            })}
                            text='Hae viimeisin' />
                        <IconButton
                            className='orange-white-btn-get-latest'
                            icon='cancel'
                            isMobile={false}
                            onClick={emptyInputs({
                                setValues,
                                initialValues,
                                setDurationMinutes,
                                setDurationHours,
                                setStartMinutes,
                                setStartHours,
                                setEndMinutes,
                                setEndHours,
                                setIsInitialValues,
                            })}
                            text='Tyhjennä' />
                    </div>

                    <div className='selector-grid'>
                        {props.mainSelectors.map(selector => {
                            switch (selector.type) {
                                case 'selector':
                                    return <Selector
                                        groups={selector.groups && entryItems[selector.groups]}
                                        isDisabled={
                                            selector.dependsOn &&
                                            !values[selector.dependsOn]?.id
                                        }
                                        isInitialValues={isInitialValues}
                                        items={!selector.dependsOn ?
                                            entryItems[selector.items] :
                                            values[selector.dependsOn] &&
                                            entryItems[selector.items].filter(item =>
                                                item.group_id === values[selector.dependsOn].id)
                                        }
                                        key={selector.name}
                                        name={selector.name}
                                        selected={values[selector.name]}
                                        setSelected={handleChange}
                                        title={selector.title}
                                        workerChangeIsDisabled={props.workerChangeIsDisabled && selector.title === workerString}
                                    />
                                case 'quantityselector':
                                    return <QuantitySelector
                                        isInitialValues={isInitialValues}
                                        key={selector.name}
                                        name={selector.name}
                                        selected={values[selector.name]}
                                        selectedLoad={values.load}
                                        setSelected={handleChange}
                                        title={selector.title}
                                        units={selector.items ? entryItems[selector.items] : [{ text: 'km' }]}
                                    />
                                default:
                                    return null
                            }
                        })}
                    </div>
                    <h2>Lisätiedot</h2>
                    <p>{props.additionalInfo}</p>
                    <div className='selector-grid'>
                        {props.additionalSelectors.map(selector => {
                            switch (selector.type) {
                                case 'commentselector':
                                    return <CommentSelector
                                        isInitialValues={isInitialValues}
                                        key={selector.name}
                                        name={selector.name}
                                        selected={values[selector.name]}
                                        setSelected={handleChange}
                                        title={selector.title}
                                    />
                                case 'fileselector':
                                    return <FileSelector
                                        key={selector.name}
                                        orderId={orderId}
                                        selected={orderAttachmentIds}
                                        setOrderId={setOrderId}
                                        setSelected={handleOrderAttachmentIds(setOrderAttachmentIds)}
                                        title={selector.title}
                                    />
                                case 'dateselector':
                                    return <DateSelector
                                        key={selector.name}
                                        name={selector.name}
                                        selected={values[selector.name]}
                                        setSelected={handleChange}
                                        title={selector.title}
                                    />
                                case 'timeselector':
                                    return <TimeSelector
                                        calculateDuration={calculateDurationCallback}
                                        firstValue={whichTimeSelector(
                                            selector.name,
                                            {
                                                startHours,
                                                setStartHours,
                                                startMinutes,
                                                setStartMinutes,
                                                endHours,
                                                setEndHours,
                                                endMinutes,
                                                setEndMinutes,
                                            }
                                        ).hours}
                                        isInitialEditing={isInitialEditing}
                                        keepWithinRange={keepWithinRangeCallback}
                                        key={selector.name}
                                        name={selector.name}
                                        secondValue={whichTimeSelector(
                                            selector.name,
                                            {
                                                startHours,
                                                setStartHours,
                                                startMinutes,
                                                setStartMinutes,
                                                endHours,
                                                setEndHours,
                                                endMinutes,
                                                setEndMinutes,
                                            }
                                        ).minutes}
                                        setFirstValue={whichTimeSelector(
                                            selector.name,
                                            {
                                                startHours,
                                                setStartHours,
                                                startMinutes,
                                                setStartMinutes,
                                                endHours,
                                                setEndHours,
                                                endMinutes,
                                                setEndMinutes,
                                            }
                                        ).setHours}
                                        setIsInitialEditing={setIsInitialEditing}
                                        setSecondValue={whichTimeSelector(
                                            selector.name,
                                            {
                                                startHours,
                                                setStartHours,
                                                startMinutes,
                                                setStartMinutes,
                                                endHours,
                                                setEndHours,
                                                endMinutes,
                                                setEndMinutes,
                                            }
                                        ).setMinutes}
                                        title={selector.title}
                                        valueDown={valueDown(setIsInitialEditing)}
                                        valueUp={valueUp(setIsInitialEditing)}
                                    />
                                case 'durationselector':
                                    return <DurationSelector
                                        firstValue={durationHours}
                                        keepWithinRange={keepWithinRangeCallback}
                                        key={selector.name}
                                        name={selector.name}
                                        secondValue={durationMinutes}
                                        setFirstValue={setDurationHours}
                                        setSecondValue={setDurationMinutes}
                                        title={selector.title}
                                        valueDown={valueDown(setIsInitialEditing)}
                                        valueUp={valueUp(setIsInitialEditing)}
                                    />
                                default:
                                    return null
                            }
                        })}
                    </div>
                    <div className='flex-center margin-top'>
                        <IconButton
                            className='orange-white-btn'
                            icon='save'
                            isMobile={false}
                            onClick={saveRecord(
                                props.jobWillBeConvertedToOrder,
                                props.typeId,
                                props.handleOrderButtonAction,
                                props.turnJobToOrder,
                                {
                                    isEditing,
                                    setIsLoading,
                                    id,
                                    userInfo,
                                    orderAssignment,
                                    geolocation,
                                    navigate,
                                    values,
                                    dispatch,
                                    durationHours,
                                    durationMinutes,
                                    startHours,
                                    startMinutes,
                                    endHours,
                                    endMinutes,
                                    orderAttachmentIds,
                                }
                            )}
                            text={'Tallenna'} />
                    </div>
                </> :
                <Loader
                    active={isLoading || loading}
                    className='content-loader'
                    inline='centered' />

            }
        </div>
    )
}

OrdersPage.defaultProps = {
    jobWillBeConvertedToOrder: false,
    orderAssignmentIsEnabled: true,
}

OrdersPage.propTypes = {
    additionalInfo: PropTypes.string.isRequired,
    additionalSelectors: PropTypes.array.isRequired,
    handleOrderButtonAction: PropTypes.func.isRequired,
    jobWillBeConvertedToOrder: PropTypes.bool,
    mainInfo: PropTypes.string.isRequired,
    mainSelectors: PropTypes.array.isRequired,
    orderAssignmentIsEnabled: PropTypes.bool,
    title: PropTypes.string.isRequired,
    turnJobToOrder: PropTypes.func.isRequired,
    typeId: PropTypes.number.isRequired,
    user: PropTypes.object.isRequired,
    workerChangeIsDisabled: PropTypes.bool.isRequired,
}

export default OrdersPage
