export const handleKeyUp = e => e.key === 'Enter' && e.target.blur()

const regex = /[^\d]/g

export const handleChange = (name, setSelected, setValue, setUnit, unit) => (e, selectedOption) => {
    if (e?.target?.value) {
        const inputValue = e.target.value.replace(regex, '')

        if (inputValue) {
            const maxLengthLimitForTextInput = name === 'route_length' ? 10 : 18
            const textWithLengthLimit = inputValue.slice(0, maxLengthLimitForTextInput)

            setValue(textWithLengthLimit)
            setSelected({ text: textWithLengthLimit, unit_id: unit.unit_id, unit_text: unit.unit_text }, name)
        }

        return
    }

    if (selectedOption?.options) {
        const unitText = selectedOption.options.find(item => item.value === selectedOption.value).text

        setUnit({ unit_id: selectedOption.value, unit_text: unitText })
        setSelected({ unit_id: selectedOption.value, unit_text: unitText }, name)

        return
    }

    setValue('')
    setSelected(null, name)
}
