export const handleModalToggle = (setIsActive, isActive) => () => {
    setIsActive(!isActive)
}
export const handleClear = (setInputDisabled, setPlaceholderValueOnDisabledInput, setSelected, name) => {
    setInputDisabled(false)
    setPlaceholderValueOnDisabledInput('')
    setSelected(null, name)
}
export const handleSelect = (setInputDisabled, setSelected, name, setIsActive, setPlaceholderValueOnDisabledInput) => item => {
    setInputDisabled(true)
    setSelected(item, name)
    setIsActive(false)
    setPlaceholderValueOnDisabledInput(item.text)
}
export const handleInputChange = (setPlaceholderValueOnDisabledInput, setSelected, name) => ({ target }) => {
    const targetValueWithLengthLimit = target?.value.slice(0, 200)

    const [placeholderValue, selectedValue] = target?.value ?
        [targetValueWithLengthLimit, { text: targetValueWithLengthLimit }] :
        ['', null]

    setPlaceholderValueOnDisabledInput(placeholderValue)
    setSelected(selectedValue, name)
}

export const handleDeleteClick = setSelectedGroup => () => {
    setSelectedGroup(null)
}
