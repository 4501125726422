import { errorAction, expiredTokenAction, successAction } from '../notification-reducer/notification-reducer'
import { lambdaCall } from '../../services/aws-lambda-service/aws-lambda-service'
import { loginAction } from '../login-reducer/login-reducer'
import { logout } from '../../utils/redux-dispatch-configurations'

// ACTION TYPES
const REQUEST = 'GROUPS-REQUEST'
const SUCCESS = 'GROUPS-SUCCESS'
const GET_FAILURE = 'GET-GROUPS-FAILURE'
const CREATE_GROUP = 'CREATE-GROUP'
const EDIT_GROUP = 'EDIT-GROUP'
const DELETE_GROUP = 'DELETE-GROUP'
const initialState = { customergroups: undefined, usergroups: undefined }
const duplicateErrorMessage = 'Group is duplicate'

const mapGroupsForEdit = (groups, action) => groups.map(group => group.id === action.payload.id ?
    {
        id: action.payload.id,
        name: action.payload.name,
        subgroups: action.payload.groups,
    } :
    group)

// REDUCER
export const groupsReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case REQUEST:
            return { ...state, loading: true }

        case SUCCESS: {
            const customergroups = action.payload.customergroups.map(group => ({
                id: group.cid,
                name: group.cname,
                subgroups: action.payload.cgug.filter(item => item.customergroup_id === group.cid).map(item => item.usergroup_id),
            }))

            const usergroups = action.payload.usergroups.map(group => ({
                id: group.uid,
                name: group.uname,
                subgroups: action.payload.ugcg.filter(item => item.usergroup_id === group.uid).map(item => item.customergroup_id),
            }))

            return {
                ...state,
                loading: false,
                customergroups: customergroups,
                usergroups: usergroups,
                defaultUsergroupId: action.payload.defaultUsergroupId,
                defaultCustomergroupId: action.payload.defaultCustomergroupId,
            }
        }

        case GET_FAILURE:
            return { ...state, loading: false, groupError: true }
        case CREATE_GROUP:
            return {
                ...state,
                loading: false,
                usergroups: action.usergroup ?
                    [
                        ...state.usergroups,
                        {
                            id: action.payload.id,
                            name: action.payload.name,
                            subgroups: action.payload.groups,
                        },
                    ] :
                    state.usergroups,
                customergroups: !action.usergroup ?
                    [
                        ...state.customergroups,
                        {
                            id: action.payload.id,
                            name: action.payload.name,
                            subgroups: action.payload.groups,
                        },
                    ] :
                    state.customergroups,
            }
        case EDIT_GROUP:
            return {
                ...state,
                loading: false,
                usergroups: mapGroupsForEdit(state.usergroups, action),
                customergroups: mapGroupsForEdit(state.customergroups, action),
            }
        case DELETE_GROUP:
            return {
                ...state,
                loading: false,
                usergroups: state.usergroups.filter(item => item.id !== action.payload.id),
                customergroups: state.customergroups.filter(item => item.id !== action.payload.id),
            }
        default:
            return state
    }
}

// ACTION CREATORS
export const groupAction = (body, config) => async dispatch => {
    try {
        if (config.request)
            dispatch({ type: config.request })

        const response = await lambdaCall({ body: body, endpoint: config.endpoint, duplicateErrorMessage: duplicateErrorMessage })

        dispatch({
            type: config.dispatchType,
            payload: response.data,
            usergroup: body.usergroup,
        })

        config.successText && dispatch(successAction(config.successText))
    } catch (error) {
        if (error.message !== 'canceled') {
            dispatch({ type: GET_FAILURE })
            dispatch(errorAction())
        }

        if (error.response?.data.message === 'Count exceeds limit') {
            const groupString = body.usergroup ? 'Käyttäjäryhmiä' : 'Asiakasryhmiä'

            dispatch(errorAction(`${groupString} voi olla enintään ${error.response.data.groupLimit}`))
        }

        if (error.message === duplicateErrorMessage)
            dispatch(errorAction(`"${body.name}" on jo olemassa`))

        if (error.message === 'Token expired') {
            dispatch(loginAction({}, logout))
            dispatch(expiredTokenAction())
        }
    }
}
