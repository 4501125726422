import AWS from 'aws-sdk'
import storage from '../../utils/storage'

const userKey = 'MARANET_LOGGED_USER'
const refreshTokenAuth = 'REFRESH_TOKEN_AUTH'

AWS.config.region = process.env.REACT_APP_AWS_REGION

const cognito = new AWS.CognitoIdentityServiceProvider()

export const getNewCognitoTokens = () => {
    const MARANET_LOGGED_USER = JSON.parse(localStorage.getItem(userKey))

    const params = {
        AuthFlow: refreshTokenAuth,
        AuthParameters: { REFRESH_TOKEN: MARANET_LOGGED_USER.refresh_token },
        ClientId: process.env.REACT_APP_AWS_CLIENT_ID,
    }

    return cognito.initiateAuth(params)
        .promise()
        .then(res => {
            MARANET_LOGGED_USER.id_token = res.AuthenticationResult.IdToken
            MARANET_LOGGED_USER.access_token = res.AuthenticationResult.AccessToken
            window.localStorage.setItem(userKey, JSON.stringify(MARANET_LOGGED_USER))

            return { statusCode: 200 }
        })
        .catch(error => {
            if (error.statusCode === 400) {
                storage.logoutUser()

                throw new Error('Token expired')
            } else {
                throw new Error('Error')
            }
        })
}
