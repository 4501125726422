const addrs = require('email-addresses')

const regexPatterns = {
    password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[=+\-^$*.[\]{}()?"!@#%&/\\,><':;|_~`])\S{8,256}$/,
    tel: /^\+[+]?[(]?\d{3}\)?[-\s.]?\d{3}[-\s.]?\d{4,6}$/,
}

const getFieldValidationError = (input, type) => {
    let isError = false

    switch (type) {
        case 'email':
            isError = !addrs(input)
            break
        case 'text':
            isError = input.trim() === ''
            break
        case 'tel':
        case 'password':
            isError = !regexPatterns[type].test(input)
            break
        case undefined:
            break
        default:
            throw new Error(`Field type '${type}' is not supported`)
    }

    return isError
}

const errorMessages = {
    tel: 'Puhelinnumeron tulee olla kansainvälisessä muodossa +358123123122',
    email: 'Sähköpostin tulee olla muotoa henkilo@domain.com',
    text: 'Tekstikentän tulee olla täytetty',
}

module.exports = {
    getFieldValidationError,
    errorMessages,
}
