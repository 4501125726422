import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { hasAccess } from '../../utils/route-access'
import PropTypes from 'prop-types'
import { handleNavigate, getClass } from './menu-item.functions'
import './menu-item.css'

const MenuItem = props => {
    const navigate = useNavigate()
    const { user } = useSelector(state => state.userData)

    if (!hasAccess(`/${props.path}`, user.roles))
        return null

    return (
        <div
            className={getClass(props.selected, props.path, 'menu-item selected', 'menu-item')}
            onClick={handleNavigate(navigate, props.path)}>
            {props.title}
            <div className={getClass(props.selected, props.path, 'arrow-up selected')} />
        </div>
    )
}

MenuItem.propTypes = {
    path: PropTypes.string.isRequired,
    selected: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
}

export default MenuItem
