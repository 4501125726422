import React, { useState } from 'react'
import { Icon, Table, Search } from 'semantic-ui-react'
import {
    handleClearClick,
    sortColumnClick,
    handleSearchInputChange,
    handleOpenClick,
    handleContent,
} from './table-with-edit.functions'
import PropTypes from 'prop-types'
import './table-with-edit.css'

const hideInMobileClass = 'hide-in-mobile'
const ascending = 'ascending'
const fi = 'fi'

const TableWithEdit = props => {
    const [sort, setSort] = useState({ column: null, direction: ascending })
    const [filter, setFilter] = useState('')
    const filterKey = props.tableName === 'worksites' ? 'customer_id' : 'group_id'

    let tableItems = props.filterValue ? props.items.filter(item => item[filterKey] === props.filterValue) : props.items

    if (filter !== '') {
        tableItems =
            props.currentPage === 'users' ?
                tableItems.filter(item => item.email.toLowerCase().includes(filter.toLowerCase())) :
                tableItems.filter(item => item.name.toLowerCase().includes(filter.toLocaleLowerCase()))
    }

    if (sort.column) {
        tableItems = tableItems.sort((a, b) => {
            if (!a[sort.column] || !b[sort.column])
                return -1
            else if (sort.direction === ascending)
                return a[sort.column].localeCompare(b[sort.column], fi)

            return b[sort.column].localeCompare(a[sort.column], fi)
        })
    }

    return (
        <div className='add-values-table'>
            <Search
                className='search-bar-size'
                fluid
                icon={filter !== '' ?
                    <Icon
                        link
                        name='delete'
                        onClick={handleClearClick(setFilter)} /> :
                    <Icon name='search' />}
                input={{}}
                onSearchChange={handleSearchInputChange(setFilter)}
                results={[]}
                showNoResults={false}
                size='small'
                value={filter}
            />
            <div className=''>
                <Table
                    celled
                    fixed
                    selectable
                    sortable
                    unstackable>
                    <Table.Header>
                        <Table.Row>
                            {props.columns.map(column =>
                                <Table.HeaderCell
                                    className={column.hideInMobile ? hideInMobileClass : null}
                                    key={column.header}
                                    onClick={sortColumnClick(column, sort, setSort)}
                                    sorted={sort.column === column.valueName ? sort.direction : null}
                                    width={4}
                                >
                                    {column.header}
                                </Table.HeaderCell>)}
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {tableItems.map(item =>
                            <Table.Row
                                active={props.edit && props.edit.id === item.id}
                                key={item.id}
                                onClick={handleOpenClick(props.onEdit, item)}
                            >
                                {props.columns.map(column =>
                                    <Table.Cell
                                        className={column.hideInMobile ? hideInMobileClass : 'table-with-edit-cell'}
                                        content={handleContent(props.currentPage, item, column)}
                                        key={column.header}
                                        style={{
                                            color: item.disabled ? 'lightgray' : null,
                                            userSelect: 'none',
                                            fontWeight: item.id === props.defaultGroupId ? 'bold' : 'normal',
                                        }}
                                    />)}
                            </Table.Row>)}
                    </Table.Body>
                </Table>
            </div>
        </div>
    )
}

TableWithEdit.defaultProps = {
    currentPage: '',
    defaultGroupId: null,
    filterValue: '',
    items: [],
    tableName: '',
}

TableWithEdit.propTypes = {
    columns: PropTypes.array.isRequired,
    currentPage: PropTypes.string,
    defaultGroupId: PropTypes.number,
    edit: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.object,
    ]).isRequired,
    filterValue: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    items: PropTypes.array,
    onEdit: PropTypes.func.isRequired,
    tableName: PropTypes.string,
}

export default TableWithEdit
