import React, { useState } from 'react'
import MainTab from '../../components/main-tab/main-tab'
import GroupPage from '../../components/group-page/group-page'
import { useNavigate, useMatch } from 'react-router-dom'
import { returnToSameSubPageAfterRefresh } from './groups-menu.functions'

const userGroupTab = <GroupPage
    groupname='usergroups'
    key='usergroups'
    title='Hallinnoi käyttäjäryhmiä' />

const customerGroupTab = <GroupPage
    groupname='customergroups'
    key='customergroups'
    title='Hallinnoi asiakasryhmiä' />

const GroupsMenu = () => {
    const [active, setActive] = useState('kayttajaryhmat')
    const match = useMatch('/ryhmat/:active')
    const navigate = useNavigate()

    if (match && active !== match.params.active)
        returnToSameSubPageAfterRefresh(setActive, match.params.active)

    const tab = active === 'kayttajaryhmat' ? userGroupTab : customerGroupTab

    return (
        <div>
            <div className='main-row'>
                <div onClick={(() => navigate('kayttajaryhmat'))}>
                    <MainTab
                        active={active}
                        setActive={setActive}
                        tabName={'kayttajaryhmat'}
                        title='Käyttäjäryhmät' />
                </div>
                <div onClick={(() => navigate('asiakasryhmat'))}>
                    <MainTab
                        active={active}
                        setActive={setActive}
                        tabName={'asiakasryhmat'}
                        title='Asiakasryhmät' />
                </div>
            </div>
            <div className='main-content'>
                {tab}
            </div>
        </div>
    )
}

export default GroupsMenu
