const base = process.env.REACT_APP_API_URL

export const awsApiEndpoints = {
    login: { url: `${base}user-login`, method: 'post' },
    registration: { url: `${base}user-migration`, method: 'post' },
    verifyUser: { url: `${base}user-migration-verify`, method: 'post' },
    resendConfirmationCode: { url: `${base}resend-confirmation-code`, method: 'post' },
    changePassword: { url: `${base}password-recovery-confirm`, method: 'post' },
    setPassword: { url: `${base}set-password`, method: 'post' },
    getOrders: { url: `${base}get-orders`, method: 'post' },
    orderPageEntryItems: { url: `${base}get-entry-items`, method: 'get' },
    fullEntryItems: { url: `${base}get-full-entry-items`, method: 'get' },
    createOrder: { url: `${base}create-order`, method: 'post' },
    editOrder: { url: `${base}edit-order`, method: 'put' },
    deleteOrder: { url: `${base}delete-order`, method: 'put' },
    createEntryItem: { url: `${base}create-entry-item`, method: 'post' },
    editEntryItem: { url: `${base}edit-entry-item`, method: 'put' },
    deleteEntryItem: { url: `${base}delete-entry-item`, method: 'put' },
    groups: { url: `${base}get-groups`, method: 'get' },
    createGroups: { url: `${base}create-groups`, method: 'post' },
    editGroups: { url: `${base}edit-groups`, method: 'put' },
    deleteGroups: { url: `${base}delete-groups`, method: 'delete' },
    getUsers: { url: `${base}get-users`, method: 'get' },
    createUser: { url: `${base}create-user`, method: 'post' },
    editUser: { url: `${base}edit-user`, method: 'put' },
    deleteUser: { url: `${base}delete-user`, method: 'delete' },
    editPersonalInformation: { url: `${base}edit-personal-information`, method: 'post' },
    reports: { url: `${base}get-reports`, method: 'post' },
    reportEntryItems: { url: `${base}get-report-entry-items`, method: 'post' },
    revokeRefreshToken: { url: `${base}revoke-refresh-token`, method: 'post' },
    excel: { url: `${base}get-excel`, method: 'post' },
    fileAttachment: { url: `${base}file-attachment`, method: 'post' },
    acceptEula: { url: `${base}accept-eula`, method: 'post' },
    sendPasswordChangeLink: { url: `${base}send-password-change-link`, method: 'post' },
    getFileAttachmentLinks: { url: `${base}get-file-attachment-links`, method: 'post' },
    deleteFileAttachments: { url: `${base}delete-file-attachments`, method: 'delete' },
}
