import { lambdaCall } from '../../../services/aws-lambda-service/aws-lambda-service'
import { awsApiEndpoints } from '../../../utils/aws-api-endpoints'
import { successAction, errorAction } from 'src/reducers/notification-reducer/notification-reducer'
import axios from 'axios'
import JSZip from 'jszip'
import { saveAs } from 'file-saver'

const getAddImageError = (target, previewFiles) => {
    if (target.files[0]?.size > 52428800)
        return 'Liitetiedosto on liian suuri, sallittu koko on enintään 50 MB'

    if (!target.files[0]?.type.includes('image'))
        return 'Liitetiedostoon voi liittää vain kuvia'

    if (previewFiles.length >= 10)
        return 'Liitetiedostoja voi liittää enintään 10 kpl'

    return null
}

const handleImageUploadAsync = async (setIsLoading, selected, setObjectUrls, throwError, event) => {
    setIsLoading(true)

    try {
        const uniqueSuffix = Date.now()
        const newFileName = `${uniqueSuffix}-${event.target.files[0].name}`
        const result = await lambdaCall({ body: { file: newFileName }, endpoint: awsApiEndpoints.fileAttachment })

        selected.push(result.data.insertOrderAttachmentToDatabaseResult.order_attachment_id)
        await axios.put(result.data.s3Result, event.target.files[0])

        await getImageLinks(
            result.data.insertOrderAttachmentToDatabaseResult.order_attachment_id,
            null,
            setObjectUrls,
            throwError,
            setIsLoading,
        )
    } catch (error) {
        throwError(error)
    } finally {
        setIsLoading(false)
    }
}

const getImageUrls = async (orderAttachmentId, orderId, throwError) => {
    try {
        const result = await lambdaCall({
            body: {
                orderAttachmentId: orderAttachmentId,
                orderId: orderId,
            },
            endpoint: awsApiEndpoints.getFileAttachmentLinks,
        })

        return result.data.objectUrls
    } catch (error) {
        throwError(error)
    }

    return null
}

export const handleImageUpload = (
    dispatch,
    setIsLoading,
    selected,
    setObjectUrls,
    throwError,
    previewFiles,
) => event => {
    const imageError = getAddImageError(event.target, previewFiles)

    if (imageError) {
        dispatch(errorAction(imageError))

        return
    }

    handleImageUploadAsync(setIsLoading, selected, setObjectUrls, throwError, event).catch(console.error)
}

export const deleteImage = async (id, key, dispatch, throwError, setIsLoading) => {
    setIsLoading(true)

    try {
        await lambdaCall({
            body: { orderAttachmentIds: [id], objects: [{ Key: key }] },
            endpoint: awsApiEndpoints.deleteFileAttachments,
        })

        dispatch(successAction('Kuvan poisto onnistui'))
    } catch (error) {
        throwError(error)
    } finally {
        setIsLoading(false)
    }
}

export const handleFileDelete = ({
    index,
    key,
    setPreviewFiles,
    previewFiles,
    setSelected,
    setIsOpen,
    dispatch,
    throwError,
    setIsLoading,
}) => () => {
    setPreviewFiles([...previewFiles.filter(item => item.id !== index)])
    setSelected(previewFiles.filter(item => item.id !== index).map(file => file.id))
    deleteImage(index, key, dispatch, throwError, setIsLoading).catch(console.error)

    if (previewFiles.length === 1)
        setIsOpen(false)
}

export const getImageLinks = async (orderAttachmentId, id, setObjectUrls, throwError, setIsLoading) => {
    try {
        const result = await lambdaCall({
            body: {
                orderAttachmentId: orderAttachmentId,
                orderId: id,
            },
            endpoint: awsApiEndpoints.getFileAttachmentLinks,
        })

        if (result.data.objectUrls)
            setObjectUrls(result.data.objectUrls, id)
    } catch (error) {
        throwError(error)
    } finally {
        setIsLoading(false)
    }
}

export const handleModalToggle = (setIsOpen, bool) => () => setIsOpen(bool)

export const handleInputRefClick = fileInputRef => () => fileInputRef.current.click()

export const showImage = (id, loadedImageIds, setLoadedImageIds) => () => {
    if (!loadedImageIds.includes(id))
        setLoadedImageIds([...loadedImageIds, id])
}

export const handleImageDownload = (setImageLoading, throwError, orderId, orderAttachmentId) => async e => {
    e.stopPropagation()
    setImageLoading(orderAttachmentId || true)

    const promises = []
    const zip = new JSZip()
    const result = await getImageUrls(orderAttachmentId, orderId, throwError)

    if (!result) {
        setImageLoading(false)

        return
    }

    if (orderAttachmentId) {
        axios(result[0].url, { responseType: 'blob' }).then(res => {
            saveAs(res.data, result[0].name)
            setImageLoading(false)
        }).catch(error => {
            setImageLoading(false)
            throwError(error)
        })
    }

    if (orderId) {
        for (const [i] of Object.entries(result)) {
            promises.push(axios(result[i].url, { responseType: 'blob' }).then(res => {
                zip.file(result[i].name, res.data, { base64: true })
            }))
        }

        Promise.all(promises).then(() => {
            zip.generateAsync({ type: 'blob' })
                .then(content => {
                    saveAs(content, 'images.zip')
                    setImageLoading(false)
                })
        }).catch(error => {
            setImageLoading(false)
            throwError(error)
        })
    }
}
