import React from 'react'
import './page-footer.css'

const PageFooter = () =>
    <>
        <div className='grid-footer footer'>
            Tutustu maranetin käyttöön
            <div className='yt-btn'>Katso ohjevideot YouTubesta</div>
        </div>
        <div className='side-left' />
        <div className='side-right' />
    </>

export default PageFooter
