const arrFrom = x => Array.from(x)

export const nullish = (nonNullValue, defaultValue) => nonNullValue ?? defaultValue
export const ifTrueThenValue = (conditions, value) => arrFrom(conditions).length > 0 && arrFrom(conditions).every(x => x === true) && value
export const ifTrueThenValueElse = (conditions, value, otherwise) => ifTrueThenValue(conditions, value) || otherwise
export const getStorageInfoJson = infoName => JSON.parse(localStorage.getItem('MARANET_LOGGED_USER'))[infoName]
export const locationIsDisabled = location => !location.lat && !location.lng
export const addLeftPadding = (input, padAmount = 2, padCharacter = '0') => input.toString().padStart(padAmount, padCharacter)

/**
 * Returns an array of key-value-text objects with a basic key-value-pair object as input
 * @param {*} object Object such as { a: 'hello', b: 'world' }
 * @param {*} keyName Value used for the 'key' key, default is 'key'
 * @param {*} valueName Value used for the 'value' key, default is 'value'
 * @param {*} textName Value used for the 'text' key, default is 'text'
 * @returns Following the example above, returns an array in the form of
 * [
 *  {
 *      key: 'a',
 *      value: 0,
 *      text: 'hello'
 *  },
 *  {
 *      key: 'b',
 *      value: 1,
 *      text: 'world'
 *  }
 * ]
 */
export const getObjectAsKeyValueTextArray = (
    object,
    keyName = 'key',
    valueName = 'value',
    textName = 'text',
) => Object.keys(object).map((key, index) => ({
    [keyName]: key,
    [valueName]: index,
    [textName]: object[key],
}))
