const workerText = 'työntekijä'
const accessoryText = 'lisävaruste'
const commentText = 'kommentti'
const deliveryDateText = 'toimituspäivä'

export const orderTypeSchemas = [
    {
        id: 1,
        type: 'kuljetus',
        title: 'Kirjaa kuljetus',
        orderAssignmentIsEnabled: true,
        mainInfo: 'Kirjaa kuljetuksen tiedot.',
        additionalInfo: 'Lisätietoja kuljetukseen liittyen.',
        mainSelectors: [
            {
                type: 'selector',
                name: 'worker',
                title: workerText,
                items: 'workers',
                groups: null,
                dependsOn: null,
            },
            {
                type: 'selector',
                name: 'customer',
                title: 'asiakas',
                items: 'customers',
                groups: 'customerGroups',
                dependsOn: null,
            },
            {
                type: 'selector',
                name: 'worksite',
                title: 'työmaa',
                items: 'worksites',
                groups: null,
                dependsOn: 'customer',
            },
            {
                type: 'selector',
                name: 'vehicle',
                title: 'kone',
                items: 'vehicles',
                groups: null,
                dependsOn: null,
            },
            {
                type: 'selector',
                name: 'pickup',
                title: 'noutopaikka',
                items: 'pickups',
                groups: null,
                dependsOn: null,
            },
            {
                type: 'quantityselector',
                name: 'route_length',
                title: 'kuljetusmatka',
                items: null,
                groups: null,
                dependsOn: null,
            },
            {
                type: 'selector',
                name: 'load',
                title: 'kuormalajike',
                items: 'loads',
                groups: null,
                dependsOn: null,
            },
            {
                type: 'quantityselector',
                name: 'quantity',
                title: 'määrä',
                items: 'units',
                groups: null,
                dependsOn: null,
            },
        ],
        additionalSelectors: [
            {
                type: 'commentselector',
                name: 'comment',
                title: commentText,
            },
            {
                type: 'fileselector',
                name: 'file',
                title: 'Liitetiedosto',
            },
            {
                type: 'dateselector',
                name: 'delivery_date',
                title: deliveryDateText,
            },
            {
                type: 'timeselector',
                name: 'start_time',
                title: 'aloitusaika',
            },
            {
                type: 'timeselector',
                name: 'end_time',
                title: 'lopetusaika',
            },
            {
                type: 'durationselector',
                name: 'duration',
                title: 'kesto',
            },
        ],
    },
    {
        id: 2,
        type: 'kaivuutyo',
        title: 'Kirjaa kaivuutyö',
        orderAssignmentIsEnabled: true,
        mainInfo: 'Kirjaa kaivuuntyön tiedot.',
        additionalInfo: 'Lisätietoja kaivuutyöhön liittyen.',
        mainSelectors: [
            {
                type: 'selector',
                name: 'worker',
                title: workerText,
                items: 'workers',
                groups: null,
                dependsOn: null,
            },
            {
                type: 'selector',
                name: 'customer',
                title: 'asiakas',
                items: 'customers',
                groups: 'customerGroups',
                dependsOn: null,
            },
            {
                type: 'selector',
                name: 'worksite',
                title: 'työmaa',
                items: 'worksites',
                groups: null,
                dependsOn: 'customer',
            },
            {
                type: 'selector',
                name: 'vehicle',
                title: 'kone',
                items: 'vehicles',
                groups: null,
                dependsOn: null,
            },
            {
                type: 'selector',
                name: 'accessory',
                title: accessoryText,
                items: 'accessories',
                groups: null,
                dependsOn: null,
            },
        ],
        additionalSelectors: [
            {
                type: 'commentselector',
                name: 'comment',
                title: commentText,
            },
            {
                type: 'fileselector',
                name: 'file',
                title: 'Liitetiedosto',
            },
            {
                type: 'dateselector',
                name: 'delivery_date',
                title: deliveryDateText,
            },
            {
                type: 'timeselector',
                name: 'start_time',
                title: 'aloitusaika',
            },
            {
                type: 'timeselector',
                name: 'end_time',
                title: 'lopetusaika',
            },
            {
                type: 'durationselector',
                name: 'duration',
                title: 'kesto',
            },
        ],
    },
    {
        id: 3,
        type: 'tuntiajo',
        title: 'Kirjaa tuntiajo',
        orderAssignmentIsEnabled: true,
        mainInfo: 'Kirjaa tuntiajon tiedot.',
        additionalInfo: 'Lisätietoja tuntiajoon liittyen.',
        mainSelectors: [
            {
                type: 'selector',
                name: 'worker',
                title: workerText,
                items: 'workers',
                groups: null,
                dependsOn: null,
            },
            {
                type: 'selector',
                name: 'customer',
                title: 'asiakas',
                items: 'customers',
                groups: 'customerGroups',
                dependsOn: null,
            },
            {
                type: 'selector',
                name: 'worksite',
                title: 'työmaa',
                items: 'worksites',
                groups: null,
                dependsOn: 'customer',
            },
            {
                type: 'selector',
                name: 'vehicle',
                title: 'kone',
                items: 'vehicles',
                groups: null,
                dependsOn: null,
            },
            {
                type: 'selector',
                name: 'accessory',
                title: accessoryText,
                items: 'accessories',
                groups: null,
                dependsOn: null,
            },
            {
                type: 'quantityselector',
                name: 'route_length',
                title: 'kuljetusmatka',
                items: null,
                groups: null,
                dependsOn: null,
            },
            {
                type: 'selector',
                name: 'load',
                title: 'kuormalajike',
                items: 'loads',
                groups: null,
                dependsOn: null,
            },
            {
                type: 'quantityselector',
                name: 'quantity',
                title: 'määrä',
                items: 'units',
                groups: null,
                dependsOn: null,
            },
        ],
        additionalSelectors: [
            {
                type: 'commentselector',
                name: 'comment',
                title: commentText,
            },
            {
                type: 'fileselector',
                name: 'file',
                title: 'Liitetiedosto',
            },
            {
                type: 'dateselector',
                name: 'delivery_date',
                title: deliveryDateText,
            },
            {
                type: 'timeselector',
                name: 'start_time',
                title: 'aloitusaika',
            },
            {
                type: 'timeselector',
                name: 'end_time',
                title: 'lopetusaika',
            },
            {
                type: 'durationselector',
                name: 'duration',
                title: 'kesto',
            },
        ],
    },
    {
        id: 4,
        type: 'tarvikkeet',
        title: 'Kirjaa tarvikkeita',
        orderAssignmentIsEnabled: false,
        mainInfo: 'Kirjaa tarvikkeiden tiedot.',
        additionalInfo: 'Lisätietoja tarvikkeisiin liittyen.',
        mainSelectors: [
            {
                type: 'selector',
                name: 'customer',
                title: 'asiakas',
                items: 'customers',
                groups: 'customerGroups',
                dependsOn: null,
            },
            {
                type: 'selector',
                name: 'worksite',
                title: 'työmaa',
                items: 'worksites',
                groups: null,
                dependsOn: 'customer',
            },
            {
                type: 'selector',
                name: 'product',
                title: 'tarvike',
                items: 'products',
                groups: 'productgroups',
                dependsOn: null,
            },
            {
                type: 'quantityselector',
                name: 'quantity',
                title: 'määrä',
                items: 'units',
                groups: null,
                dependsOn: null,
            },
        ],
        additionalSelectors: [
            {
                type: 'commentselector',
                name: 'comment',
                title: commentText,
            },
            {
                type: 'fileselector',
                name: 'file',
                title: 'Liitetiedosto',
            },
            {
                type: 'dateselector',
                name: 'delivery_date',
                title: deliveryDateText,
            },
        ],
    },
    {
        id: 5,
        type: 'henkilotyo',
        title: 'Kirjaa henkilötyö',
        orderAssignmentIsEnabled: true,
        mainInfo: 'Kirjaa henkilötyön tiedot.',
        additionalInfo: 'Lisätietoja henkilötyöhön liittyen.',
        mainSelectors: [
            {
                type: 'selector',
                name: 'worker',
                title: workerText,
                items: 'workers',
                groups: null,
                dependsOn: null,
            },
            {
                type: 'selector',
                name: 'customer',
                title: 'asiakas',
                items: 'customers',
                groups: 'customerGroups',
                dependsOn: null,
            },
            {
                type: 'selector',
                name: 'worksite',
                title: 'työmaa',
                items: 'worksites',
                groups: null,
                dependsOn: 'customer',
            },
        ],
        additionalSelectors: [
            {
                type: 'commentselector',
                name: 'comment',
                title: commentText,
            },
            {
                type: 'fileselector',
                name: 'file',
                title: 'Liitetiedosto',
            },
            {
                type: 'dateselector',
                name: 'delivery_date',
                title: deliveryDateText,
            },
            {
                type: 'timeselector',
                name: 'start_time',
                title: 'aloitusaika',
            },
            {
                type: 'timeselector',
                name: 'end_time',
                title: 'lopetusaika',
            },
            {
                type: 'durationselector',
                name: 'duration',
                title: 'kesto',
            },
        ],
    },
]
