import { errorAction } from '../../reducers/notification-reducer/notification-reducer'
import { entryItemAction } from '../../reducers/entry-items-reducer/entry-items-reducer'
import { createEntryItem, deleteEntryItem, editEntryItem } from '../../utils/redux-dispatch-configurations'

const postalCodeRegex = /^\d+$/
const groupIdString = 'group_id'

const filterBasedOnDependency = ({ inputField, props, values }) =>
    props.data[inputField.items].filter(entryItem => inputField.dependsOn ? entryItem.group_id === values[inputField.dependsOn] : true)

export const handleEditChange = (setEdit, setValues) => item => {
    setEdit(item)
    setValues(item)
}

export const handleCancelClick = (setEdit, clearFields, setDefaultGroup) => e => {
    e.preventDefault()
    setEdit(false)
    clearFields()
    setDefaultGroup(true)
}

export const handleDeleteClick = (edit, props, dispatch) => e => {
    e.preventDefault()

    const deleteValues = { id: edit.id, entry_item_type: props.tableItems }

    dispatch(entryItemAction(deleteValues, deleteEntryItem))
}

export const handleSaveClick = (values, props, edit, dispatch) => e => {
    e.preventDefault()

    const errors = getInputErrors(props, values)

    if (errors.length < 1) {
        const saveValues = { ...values, entry_item_type: props.tableItems, notificationValue: props.notificationValue }

        if (edit)
            dispatch(entryItemAction(saveValues, editEntryItem))
        else
            dispatch(entryItemAction(saveValues, createEntryItem))
    } else {
        dispatch(errorAction(errors[0]))
    }
}

const getInputErrors = (props, values) => {
    const requiredFieldsAreMissing = Array.from(props.inputFields.filter(item =>
        item.required === true)).some(x => !values[x.valueName])

    const shouldCheckPostalCode = 'postal_code' in values && values.postal_code
    const postalCodeIsInvalidLength = values.postal_code?.length !== 5
    const postalCodeFormatIsInvalid = !new RegExp(postalCodeRegex).test(values.postal_code)
    const errorMessages = []

    if (requiredFieldsAreMissing)
        errorMessages.push('Täytä kaikki pakolliset kentät')

    if (shouldCheckPostalCode && (postalCodeIsInvalidLength || postalCodeFormatIsInvalid))
        errorMessages.push('Virheellinen postinumero')

    return errorMessages
}

export const fieldShouldBeDisabled = (item, values) => item.dependsOn && !values[item.dependsOn]

export const getOptionsForSelect = params =>
    filterBasedOnDependency(params).map(elem => ({ text: elem.name, value: elem.id }))

export const getPlaceholderForSelect = params =>
    filterBasedOnDependency(params).length !== 0 ? params.inputField.placeholder : 'Ei vaihtoehtoja'

export const handleDefaultCustomerGroupChange = (setValues, defaultCustomerGroupId) => {
    setValues({
        name: '',
        address: '',
        postal_code: '',
        city: '',
        email: '',
        group_id: defaultCustomerGroupId,
    })
}

export const handleFilterValue = (values, tableItems) => {
    switch (tableItems) {
        case 'worksites':
            return values.customer_id
        case 'products':
            return values.group_id
        default:
            return ''
    }
}

export const handleClearBtn = (valueName, tableItems) =>
    tableItems !== 'customers' && valueName === groupIdString
