import React, { useState } from 'react'
import CheckboxBase from '../checkbox-base/checkbox-base'
import PropTypes from 'prop-types'

const CheckboxOptional = props => {
    const { checkedCheckboxes } = props
    const [render, setRender] = useState(false)

    const handleCheck = () => {
        if (props.id === 0) {
            props.values.labels.shift()
            props.checkAll(props.values.labels)
        } else {
            checkedCheckboxes[props.id] = Number(!checkedCheckboxes[props.id])
            checkedCheckboxes[0] = 0
            props.renderFunction()

            if (!props.optionalValues.includes(props.name))
                props.optionalValues.push(props.name)
            else
                props.optionalValues.splice(props.optionalValues.indexOf(props.name), 1)
        }

        setRender(!render)
    }

    return (
        <CheckboxBase
            isChecked={!!props.checkedCheckboxes[props.id]}
            onClickHandler={handleCheck}
        />
    )
}

CheckboxOptional.propTypes = {
    checkAll: PropTypes.func.isRequired,
    checkedCheckboxes: PropTypes.array.isRequired,
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    optionalValues: PropTypes.array.isRequired,
    renderFunction: PropTypes.func.isRequired,
    values: PropTypes.object.isRequired,
}

export default CheckboxOptional
