import React from 'react'
import PropTypes from 'prop-types'
import { handleOnClick } from './main-tab.functions'

const MainTab = props =>
    <div
        className={props.active === props.tabName ? 'tab-item tab-selected' : 'tab-item hover'}
        onClick={handleOnClick(props.setActive, props.tabName)}>
        {props.children}
        {props.title}
    </div>

MainTab.defaultProps = { active: null, children: null, tabName: null }

MainTab.propTypes = {
    active: PropTypes.string,
    children: PropTypes.element,
    setActive: PropTypes.func.isRequired,
    tabName: PropTypes.string,
    title: PropTypes.string.isRequired,
}

export default MainTab
