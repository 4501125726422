import React, { useState } from 'react'
import { useNavigate, useMatch } from 'react-router-dom'
import MainTab from '../../components/main-tab/main-tab'
import { ExcelReport, MultiReport, OrdersReport, WebviewReport } from '../report-screens'
import { returnToSameSubPageAfterRefresh, handleNavigate } from './reports-menu.functions'

const excelReport = 'tietojenvienti'
const webviewReport = 'nayttoraportti'
const ordersReport = 'laskuliite'
const multiReport = 'toimintaraportti'

const ReportsMenu = () => {
    const [active, setActive] = useState('tietojenvienti')
    const match = useMatch('/raportit/:active')
    const navigate = useNavigate()

    if (match && active !== match.params.active)
        returnToSameSubPageAfterRefresh(setActive, match.params.active)

    let tab

    switch (active) {
        case webviewReport:
            tab = <WebviewReport />
            break
        case ordersReport:
            tab = <OrdersReport />
            break
        case multiReport:
            tab = <MultiReport />
            break
        default:
            tab = <ExcelReport />
            break
    }

    return (
        <div>
            <div className='main-row'>
                <div onClick={handleNavigate(navigate, excelReport)}>
                    <MainTab
                        active={active}
                        setActive={setActive}
                        tabName={excelReport}
                        title='Tietojen vienti' />
                </div>
                <div onClick={handleNavigate(navigate, webviewReport)}>
                    <MainTab
                        active={active}
                        setActive={setActive}
                        tabName={webviewReport}
                        title='Näyttöraportti' />
                </div>
                <div onClick={handleNavigate(navigate, ordersReport)}>
                    <MainTab
                        active={active}
                        setActive={setActive}
                        tabName={ordersReport}
                        title='Laskuliite' />
                </div>
                <div onClick={handleNavigate(navigate, multiReport)}>
                    <MainTab
                        active={active}
                        setActive={setActive}
                        tabName={multiReport}
                        title='Toimintaraportti' />
                </div>
            </div>
            <div className='main-content'>
                {tab}
            </div>
        </div>
    )
}

export default ReportsMenu
