import React, { useState, useEffect } from 'react'
import { Grid, Header, Form, Segment, Button, Divider } from 'semantic-ui-react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, Link } from 'react-router-dom'
import { useInputField } from '../../hooks'
import logoImg from '../../assets/images/logo-444444.png'
import { handleResetClick } from './retrieve-password.functions'

const RetrievePassword = () => {
    // CUSTOM HOOKS
    const email = useInputField('email', 'Sähköpostiosoite', 'mail', 'Sähköpostin tulee olla muotoa henkilo@domain.com')
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { user } = useSelector(state => state.userData)

    useEffect(() => {
        if (user)
            navigate('/')
    }, [navigate, user])

    return (
        <div className="login-bg">
            <Grid
                data-testid='grid'
                style={{ height: '100vh' }}
                textAlign='center'
                verticalAlign='middle'>
                <Grid.Column
                    data-testid='grid-column'
                    style={{ maxWidth: 450 }}
                >
                    <Header>
                        <Header.Content>
                            <img
                                alt="Maranet logo"
                                className="login-logo"
                                src={logoImg} />
                        </Header.Content>
                    </Header>
                    <Form size='large'>
                        <Segment padded='very'>
                            <h2>Salasanan palautus</h2>
                            <Form.Input
                                fluid
                                iconPosition='left'
                                required
                                {...email}
                                autoComplete='email'
                                className="login-spacer"
                            />
                            <Button
                                className="login-btn login-spacer"
                                color='black'
                                fluid
                                loading={loading}
                                onClick={handleResetClick(email, setLoading, dispatch, navigate)}
                                size='large'
                                type="submit">
                                Lähetä sähköposti
                            </Button>
                            <Divider />
                            <div className="login-links">
                                <div>
                                    Takaisin <Link to="/login">sisäänkirjautumiseen</Link>
                                </div>
                            </div>
                        </Segment>
                    </Form>
                </Grid.Column>
            </Grid>
        </div>
    )
}

export default RetrievePassword
