import { awsApiEndpoints } from './aws-api-endpoints'

const ok = 'OK'
const usersRequest = 'USERS-REQUEST'
const getMoreRequest = 'GET-MORE-REQUEST'
const groupsRequest = 'GROUPS-REQUEST'

export const getOrders = {
    dispatchType: 'GET-DATA-SUCCESS',
    endpoint: awsApiEndpoints.getOrders,
    request: 'GET-DATA-REQUEST',
    reset: true,
}

export const infiniteScrollOrders = {
    dispatchType: 'GET-MORE-DATA',
    endpoint: awsApiEndpoints.getOrders,
    request: getMoreRequest,
}

export const deleteOrder = {
    dispatchType: 'DELETE-ITEM',
    endpoint: awsApiEndpoints.deleteOrder,
}

export const editOrder = {
    dispatchType: 'RESERVE-OR-OPEN-ORDERASSIGNMENT',
    endpoint: awsApiEndpoints.editOrder,
}

export const reports = {
    dispatchType: 'GET-REPORT-SUCCESS',
    endpoint: awsApiEndpoints.reports,
    request: 'GET-REPORT-REQUEST',
    reset: true,
}

export const reportInfiniteScroll = {
    dispatchType: 'GET-MORE-ORDERS',
    endpoint: awsApiEndpoints.reports,
    request: 'GET-MORE-ORDERS-REQUEST',
}

export const getFullEntryItems = {
    dispatchType: 'GET-FULL-ENTRY-ITEMS-SUCCESS',
    endpoint: awsApiEndpoints.fullEntryItems,
    request: 'GET-FULL-ENTRY-ITEMS-REQUEST',
}

export const orderPageEntryItems = {
    dispatchType: 'GET-ENTRY-ITEMS-SUCCESS',
    endpoint: awsApiEndpoints.orderPageEntryItems,
    request: 'GET-ENTRY-ITEMS-REQUEST',
}

export const createEntryItem = {
    dispatchType: 'CREATE-ENTRY-ITEMS-SUCCESS',
    endpoint: awsApiEndpoints.createEntryItem,
    request: 'CREATE-ENTRY-ITEMS-REQUEST',
    successText: ok,
}

export const editEntryItem = {
    dispatchType: 'EDIT-ENTRY-ITEMS-SUCCESS',
    endpoint: awsApiEndpoints.editEntryItem,
    request: 'EDIT-ENTRY-ITEMS-REQUEST',
    successText: ok,
}

export const deleteEntryItem = {
    dispatchType: 'DELETE-ENTRY-ITEMS-SUCCESS',
    endpoint: awsApiEndpoints.deleteEntryItem,
    request: 'DELETE-ENTRY-ITEMS-REQUEST',
    successText: ok,
}

export const getGroups = {
    dispatchType: 'GROUPS-SUCCESS',
    endpoint: awsApiEndpoints.groups,
    request: groupsRequest,
}

export const createGroup = {
    dispatchType: 'CREATE-GROUP',
    endpoint: awsApiEndpoints.createGroups,
    successText: ok,
    request: groupsRequest,
}

export const editGroup = {
    dispatchType: 'EDIT-GROUP',
    endpoint: awsApiEndpoints.editGroups,
    successText: ok,
    request: groupsRequest,
}

export const deleteGroup = {
    dispatchType: 'DELETE-GROUP',
    endpoint: awsApiEndpoints.deleteGroups,
    successText: ok,
    request: groupsRequest,
}

export const login = {
    dispatchType: 'LOGIN_SUCCESS',
    endpoint: awsApiEndpoints.login,
    request: 'LOGIN_REQUEST',
    setUserToStore(user, body) {
        return {
            access_token: user.access_token,
            business_name: user.user_business,
            email: body.email,
            id: user.worker_id,
            id_token: user.id_token,
            phone: user.user_phone,
            refresh_token: user.refresh_token,
            roles: user.user_role_ids,
            token_expiration_time: user.token_expiration_time,
            eula: user.eula,
            user_firstname: user.user_firstname,
            user_lastname: user.user_lastname,
        }
    },
}

export const logout = { dispatchType: 'LOGOUT' }

export const editPersonalInformation = {
    dispatchType: 'EDIT-INFORMATION',
    endpoint: awsApiEndpoints.editPersonalInformation,
    request: 'EDIT-INFORMATION-REQUEST',
    successText: ok,
}

export const getUsers = {
    dispatchType: 'GET-USERS-SUCCESS',
    endpoint: awsApiEndpoints.getUsers,
    request: usersRequest,
}

export const createUser = {
    dispatchType: 'CREATE-USER-SUCCESS',
    endpoint: awsApiEndpoints.createUser,
    request: usersRequest,
    successText: ok,
}

export const editUser = {
    dispatchType: 'EDIT-USER-SUCCESS',
    endpoint: awsApiEndpoints.editUser,
    request: usersRequest,
    successText: ok,
}

export const deleteUser = {
    dispatchType: 'DELETE-USER-SUCCESS',
    endpoint: awsApiEndpoints.deleteUser,
    request: usersRequest,
    successText: ok,
}

export const acceptEula = {
    dispatchType: 'ACCEPT-EULA-SUCCESS',
    endpoint: awsApiEndpoints.acceptEula,
    request: 'ACCEPT-EULA-REQUEST',
}
