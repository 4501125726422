import React from 'react'
import { Icon } from 'semantic-ui-react'
import './time-picker.css'
import PropTypes, { func } from 'prop-types'
import { handleKeyDownValidate, handlePaste, handleFocus, handleKeyUp, handleBlur } from './time-picker.functions'

const TimePicker = props =>
    <div className={'selector-box'}>
        <h3>{props.title.toUpperCase()}</h3>
        <div className='timepicker'>
            <div className='timeselect'>
                <div className='timeselect-icon'>
                    <Icon
                        data-testid={'firstUpIcon'}
                        link
                        name='chevron up'
                        onClick={props.firstAddOnClickHandler}
                        size='large'
                    />
                </div>
                <input
                    className='time'
                    data-testid={'firstInput'}
                    defaultValue={props.firstValue < 10 ? `0${props.firstValue}` : props.firstValue}
                    inputMode='numeric'
                    key={props.firstValue}
                    maxLength='2'
                    onBlur={handleBlur(props.setFirstValue, props.title, props.isInitialEditing, props.setIsInitialEditing)}
                    onFocus={handleFocus}
                    onKeyDown={handleKeyDownValidate}
                    onKeyUp={handleKeyUp}
                    onPaste={handlePaste}
                    pattern='[0-9]*'
                    type='text'
                />
                <div className='timeselect-icon'>
                    <Icon
                        data-testid={'firstDownIcon'}
                        link
                        name='chevron down'
                        onClick={props.firstSubtractOnClickHandler}
                        size='large'
                    />
                </div>
            </div>
            :
            <div className='timeselect'>
                <div className='timeselect-icon'>
                    <Icon
                        data-testid={'secondUpIcon'}
                        link
                        name='chevron up'
                        onClick={props.secondAddOnClickHandler}
                        size='large'
                    />
                </div>
                <input
                    className='time'
                    data-testid={'secondInput'}
                    defaultValue={props.secondValue < 10 ? `0${props.secondValue}` : props.secondValue}
                    inputMode='numeric'
                    key={props.secondValue}
                    maxLength='2'
                    onBlur={handleBlur(props.setSecondValue, props.title, props.isInitialEditing, props.setIsInitialEditing)}
                    onFocus={handleFocus}
                    onKeyDown={handleKeyDownValidate}
                    onKeyUp={handleKeyUp}
                    onPaste={handlePaste}
                    pattern='[0-9]*'
                    type='text'
                />
                <div className='timeselect-icon'>
                    <Icon
                        data-testid={'secondDownIcon'}
                        link
                        name='chevron down'
                        onClick={props.secondSubtractOnClickHandler}
                        size='large'
                    />
                </div>
            </div>
        </div>
    </div>

TimePicker.defaultProps = {
    isInitialEditing: false,
    setIsInitialEditing: func,
}

TimePicker.propTypes = {
    firstAddOnClickHandler: PropTypes.func.isRequired,
    firstSubtractOnClickHandler: PropTypes.func.isRequired,
    firstValue: PropTypes.number.isRequired,
    isInitialEditing: PropTypes.bool,
    secondAddOnClickHandler: PropTypes.func.isRequired,
    secondSubtractOnClickHandler: PropTypes.func.isRequired,
    secondValue: PropTypes.number.isRequired,
    setFirstValue: PropTypes.func.isRequired,
    setIsInitialEditing: PropTypes.func,
    setSecondValue: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
}

export default TimePicker
