import axios from 'axios'
import storage from '../../utils/storage'
import { getNewCognitoTokens } from '../cognito-token-service/cognito-token-service'
import axiosRetry from 'axios-retry'

const { crypto } = window
const array = new Uint8Array(1)
const tokenExpiredText = 'Token expired'
const loginTime = 'login_time'

let abortController

axiosRetry(axios, {
    retries: 3,
    retryDelay: retryCount =>
        (2 ** retryCount) - 1 + crypto.getRandomValues(array)[0],
    retryCondition: error => [408, 500, 502, 503, 504].includes(error.response?.status),
})

const lambdaCall = async ({ body, endpoint, timeoutMs = 22000, duplicateErrorMessage }) => {
    try {
        const currentTime = new Date(Date.now())

        const tokenHasExpired = (currentTime.getTime() -
            new Date(localStorage.getItem(loginTime)).getTime()) /
            1000 -
            storage.loadUser()?.token_expiration_time > 0

        if (storage.loadUser() && tokenHasExpired) {
            localStorage.setItem(loginTime, currentTime)
            await getNewCognitoTokens()
        }

        const token = storage.loadUser()?.id_token
        const headers = { Authorization: token }

        if (abortController)
            abortController.abort('canceled due to new request')

        abortController = new AbortController()

        const result = await axios({
            method: endpoint.method,
            url: endpoint.url,
            data: body,
            headers: headers,
            signal: abortController.signal,
            timeout: timeoutMs,
        })

        if (result.data.isDuplicate)
            throw new Error(duplicateErrorMessage)

        if (result.data.statusCode === 400)
            throw new Error('Invalid parameter')

        return result
    } catch (error) {
        if (error.message === tokenExpiredText)
            throw new Error(tokenExpiredText)

        throw error
    }
}

export { lambdaCall }
