import React from 'react'
import { Image } from 'semantic-ui-react'
import radiobuttonImg from '../../assets/images/radiobutton.png'
import radiobuttonEmptyImg from '../../assets/images/radiobutton-empty.png'
import { nullish } from '../../utils/helper-functions'
import PropTypes from 'prop-types'
import { getCurrentImage } from './radio-button.functions'

const mini = 'mini'

const RadioButton = props => {
    const size = nullish(props.size, mini)

    return (
        <>
            {props.valueArray.map(item =>
                <div
                    className='reports-selector-box'
                    key={item.key}>
                    <h4>{item.title}</h4>
                    <Image
                        alt={getCurrentImage(props.value, item.value, radiobuttonImg, radiobuttonEmptyImg).alt}
                        data-testid={item.title}
                        key={item.value}
                        onClick={() => props.handleValueChange(item.value)}
                        size={size}
                        src={getCurrentImage(props.value, item.value, radiobuttonImg, radiobuttonEmptyImg).image}
                    />
                </div>)}
        </>
    )
}

RadioButton.defaultProps = { size: null }

RadioButton.propTypes = {
    handleValueChange: PropTypes.func.isRequired,
    size: PropTypes.string,
    value: PropTypes.string.isRequired,
    valueArray: PropTypes.array.isRequired,
}

export default RadioButton
