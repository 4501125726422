import { lambdaCall } from '../../services/aws-lambda-service/aws-lambda-service'
import { awsApiEndpoints } from '../../utils/aws-api-endpoints'
import { successAction, errorAction } from '../../reducers/notification-reducer/notification-reducer'

const handleResetClickAsync = async (email, setLoading, dispatch, navigate) => {
    setLoading(true)

    try {
        await lambdaCall({ body: { email: email.value }, endpoint: awsApiEndpoints.sendPasswordChangeLink })
        dispatch(successAction('Sähköposti lähetetty'))

        setTimeout(() => {
            navigate('/login')
        }, 5000)
    } catch (error) {
        dispatch(errorAction('Jokin meni pieleen'))
    } finally {
        setLoading(false)
    }
}

export const handleResetClick = (email, setLoading, dispatch, navigate) => e => {
    e.preventDefault()

    if (email.value.length !== 0 && !email.error)
        handleResetClickAsync(email, setLoading, dispatch, navigate).catch(console.error)
    else
        dispatch(errorAction('Syötä oikea sähköpostiosoite'))
}
