import { ordersAction } from '../../reducers/orders-reducer/orders-reducer'
import { reportAction } from '../../reducers/reports-reducer/reports-reducer'
import { deleteOrder, editOrder } from '../../utils/redux-dispatch-configurations'

const ordersRoute = '/kirjaukset'
const deleteText = 'Poisto onnistui'

const types = {
    1: 'kuljetus',
    2: 'kaivuutyo',
    3: 'tuntiajo',
    4: 'tarvikkeet',
    5: 'henkilotyo',
}

const handleDeleteRecordClickAsync = async ({
    currentPage,
    isOrderAssignment,
    item,
    setNewLimitStart,
    setSelectedRow,
    reportType,
    dispatch,
    setLoading,
    setOpen,
}) => {
    try {
        setOpen(false)
        setLoading(true)

        if (currentPage === 'orders') {
            await dispatch(ordersAction({ orderassignment: isOrderAssignment, id: item.tilausnro }, deleteOrder, deleteText))
            setNewLimitStart(-1)
            setSelectedRow()

            return
        }

        if (currentPage === 'reports') {
            await dispatch(reportAction({ orderassignment: false, id: item.tilausnro }, deleteOrder, deleteText))
            reportType === 'byDate' && setNewLimitStart(-1)
            setSelectedRow()
        }
    } catch (err) {
        console.error(err)
    } finally {
        setLoading(false)
    }
}

export const handleOpenOrderassignmentClick = (editItem, dispatch, navigate, setSelectedRow, setLoading) => async () => {
    setLoading(true)

    const openOa = {
        id: editItem.tilausnro,
        worker_id: undefined,
    }

    await dispatch(ordersAction({ reserve_or_open: true, ...openOa }, editOrder, 'Työn vapautus onnistui'))
    navigate(ordersRoute)
    setSelectedRow()
    setLoading(false)
}

export const handleReserveOrderassignmentClick = (editItem, user, dispatch, navigate, setSelectedRow, setLoading) => async () => {
    setLoading(true)

    const reservedOa = {
        id: editItem.tilausnro,
        worker_id: user.id,
    }

    await dispatch(ordersAction({ reserve_or_open: true, ...reservedOa }, editOrder, 'Työn varaus onnistui'))
    navigate(ordersRoute)
    setSelectedRow()
    setLoading(false)
}

export const handleDeleteRecordClick = input => () => {
    handleDeleteRecordClickAsync(input).catch(console.error)
}

export const handleEditAndCopyClick = (value, navigate, item, isOrderAssignment, copy = false) => () => {
    navigate(`/kirjaukset/${types[item.type_id]}`, { state: { edit: value, item: item, oa: isOrderAssignment, copy: copy } })
}

export const handleJobToOrderClick = (turnJobToOrder, item, navigate) => () => {
    turnJobToOrder(true)

    return navigate(`/kirjaukset/${types[item.type_id]}`, { state: { edit: true, item: item } })
}

export const handleModalToggle = setSelectedRow => () => setSelectedRow()

export const handleDeleteWithAttachmentsModalToggle = (setOpen, bool) => () => {
    setOpen(bool)
}
