import React, { useState } from 'react'
import { Search, Table } from 'semantic-ui-react'
import CheckboxBase from '../checkbox-base/checkbox-base'
import PropTypes from 'prop-types'
import { getDefaultImage, handleChange, handleFilterChange, handleInputClear } from './table-with-checkbox.functions'
import './table-with-checkbox.css'

const TableWithCheckbox = props => {
    const [filter, setFilter] = useState('')
    const filteredSubgroups = props.items.filter(item => item.name.toLowerCase().includes(filter.toLowerCase()))

    return (
        <>
            <div className='grouplist-title'>
                <h4>
                    {props.title}
                </h4>
                <Search
                    className='search-list-header'
                    data-testid='searchTestId'
                    icon={
                        { name: filter ? 'delete' : 'search', link: !!filter, onClick: filter && handleInputClear(setFilter) }
                    }
                    onSearchChange={handleFilterChange(setFilter)}
                    placeholder='Rajaa tuloksia'
                    showNoResults={false}
                    size='mini'
                    value={filter}
                />
            </div>
            {
                filteredSubgroups.length > 0 ?
                    <div className='grouplist'>
                        <Table
                            celled
                            compact
                            definition
                            unstackable>
                            <Table.Body>
                                {filteredSubgroups
                                    .map(item =>
                                        <Table.Row
                                            className={item.id === props.defaultGroupId ?
                                                'defaultGroup-bold table-with-checkbox-row' :
                                                'table-with-checkbox-row'}
                                            key={item.id}
                                        >
                                            <Table.Cell collapsing>
                                                <CheckboxBase
                                                    isChecked={getDefaultImage(props.checked, item)}
                                                    onClickHandler={handleChange(props.checked, props.add, props.remove, item)}
                                                />
                                            </Table.Cell>
                                            <Table.Cell>{item.name}</Table.Cell>
                                        </Table.Row>)}
                            </Table.Body>
                        </Table>
                    </div> :
                    <div className='no-groups'>
                        Ei hakutuloksia
                    </div>
            }
        </>
    )
}

TableWithCheckbox.defaultProps = {
    defaultGroupId: null,
    items: [],
}

TableWithCheckbox.propTypes = {
    add: PropTypes.func.isRequired,
    checked: PropTypes.array.isRequired,
    defaultGroupId: PropTypes.number,
    items: PropTypes.array,
    remove: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
}

export default TableWithCheckbox
