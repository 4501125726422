import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useMatch } from 'react-router-dom'
import { Modal, Icon } from 'semantic-ui-react'
import MenuItem from '../menu-item/menu-item'
import logo from '../../assets/images/logo-444444.png'
import PersonalInformation from 'src/screens/personal-information/personal-information'
import LogoutModal from '../logout-modal/logout-modal'
import './navigation-bar.css'
import {
    setActiveTab,
    handleNavOpenState,
    handleNavigate,
    handleModalVisibilityClick,
} from './navigation-bar.functions'

const NavigationBar = () => {
    const match = useMatch('/:selection/*')
    const navigate = useNavigate()
    const { user } = useSelector(state => state.userData)
    const [selected, setSelected] = useState('')
    const [navActive, setNavActive] = useState(false)
    const [isVisible, setIsVisible] = useState(false)
    const navbarClass = navActive ? 'segment navbar nav-active' : 'segment navbar'

    if (match && selected !== match.params.selection)
        setActiveTab(setSelected, setNavActive, match)

    return (
        <>
            <div className='grid-header grid-segment'>
                <div className='segment row'>
                    <div
                        className='header-logo'
                        onClick={handleNavigate(navigate)}>
                        <img
                            alt='Maranet logo'
                            className='logo'
                            src={logo} />
                    </div>
                    <div className='header-business hide-in-mobile-tight'>{user.business_name}</div>
                    <div className='header-user user-panel'>
                        <Modal
                            dimmer='inverted'
                            open={isVisible}
                            size={'small'}
                            trigger={
                                <div
                                    className='user-div hover'
                                    onClick={handleModalVisibilityClick(setIsVisible, true)}
                                >
                                    <Icon
                                        className="user-icon"
                                        name='user' />
                                    <div className='hide-in-mobile-tight user-icon'>
                                        {user.user_firstname} {user.user_lastname}
                                    </div>
                                </div>
                            }
                        >
                            <Modal.Content>
                                <PersonalInformation onClose={handleModalVisibilityClick(setIsVisible, false)} />
                            </Modal.Content>
                        </Modal>
                        <LogoutModal isMobile={false} />
                        <div
                            className='nav-stack-btn mobile-screen hover'
                            data-testid='navbar-button'
                            onClick={handleNavOpenState(setNavActive, navActive)}
                        >
                            &#9776;
                        </div>
                    </div>
                </div>
            </div>
            <div className='grid-menu grid-segment'>
                <div
                    className={navbarClass}
                    data-testid='navbar-div'
                >
                    <MenuItem
                        path='kirjaukset'
                        selected={selected}
                        title='Kirjaukset' />
                    <MenuItem
                        path='kirjaustiedot'
                        selected={selected}
                        title='Kirjaustiedot' />
                    <MenuItem
                        path='raportit'
                        selected={selected}
                        title='Raportit' />
                    <MenuItem
                        path='kayttajat'
                        selected={selected}
                        title='Käyttäjät' />
                    <MenuItem
                        path='ryhmat'
                        selected={selected}
                        title='Ryhmät' />
                    {/* <MenuItem selected={selected} path='tarkastus' title='Tarkastus' /> */}
                    <LogoutModal isMobile={true} />
                </div>
            </div>
        </>
    )
}

export default NavigationBar
