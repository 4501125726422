import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import TimePicker from '../../time-picker/time-picker'
import {
    handleFirstAddOnClick,
    handleFirstSubtractOnClick,
    handleSecondAddOnClick,
    handleSecondSubtractOnClick,
} from '../time-selector/time-selector.functions'

const DurationSelector = props => {
    const {
        keepWithinRange,
        firstValue,
        secondValue,
        setFirstValue,
        setSecondValue,
    } = props

    useEffect(() => {
        keepWithinRange(firstValue, setFirstValue, 23)
        keepWithinRange(secondValue, setSecondValue, 59)
    }, [keepWithinRange, firstValue, setFirstValue, secondValue, setSecondValue])

    return (
        <TimePicker
            firstAddOnClickHandler={handleFirstAddOnClick(props.valueUp, props.name, firstValue, setFirstValue)}
            firstSubtractOnClickHandler={handleFirstSubtractOnClick(props.valueDown, props.name, firstValue, setFirstValue)}
            secondAddOnClickHandler={handleSecondAddOnClick(props.valueUp, props.name, secondValue, setSecondValue)}
            secondSubtractOnClickHandler={handleSecondSubtractOnClick(props.valueDown, props.name, secondValue, setSecondValue)}
            {...props}
        />
    )
}

DurationSelector.propTypes = {
    firstValue: PropTypes.number.isRequired,
    keepWithinRange: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    secondValue: PropTypes.number.isRequired,
    setFirstValue: PropTypes.func.isRequired,
    setSecondValue: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    valueDown: PropTypes.func.isRequired,
    valueUp: PropTypes.func.isRequired,
}

export default DurationSelector
