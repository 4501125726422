const text = 'text'

export const handleFocus = e => e.target.select()
export const handlePaste = e => isNaN(Number(e.clipboardData.getData(text))) && e.preventDefault()
export const handleKeyDownValidate = e => !e.key.match('^[01234567890]*$') && e.preventDefault()
export const handleKeyUp = e => e.key === 'Enter' && e.target.blur()
export const handleBlur = (set, title, isInitialEditing, setIsInitialEditing) => e => {
    set(Number(e.target.value))
    title !== 'duration' && isInitialEditing && setIsInitialEditing()
}
