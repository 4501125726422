import { lambdaCall } from '../../services/aws-lambda-service/aws-lambda-service'
import { getStorageInfoJson } from '../../utils/helper-functions'
import { awsApiEndpoints } from '../../utils/aws-api-endpoints'
import { loginAction } from '../../reducers/login-reducer/login-reducer'
import { logout } from '../../utils/redux-dispatch-configurations'

export const handleLogoutClick = dispatch => () => {
    const body = {
        email: getStorageInfoJson('email'),
        refreshToken: getStorageInfoJson('refresh_token'),
    }

    lambdaCall({ body: body, endpoint: awsApiEndpoints.revokeRefreshToken })
        .then(() => dispatch(loginAction({}, logout)))
        .catch(console.error)
}
