import React from 'react'
import { Button, Divider, Form, Segment } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

const RegistrationForm = props => {
    const errorsExist = Object.values(props).map(x => x.error).some(error => error !== null && error !== undefined)
    const registrationButtonIsDisabled = errorsExist

    return (
        <Form
            noValidate
            onSubmit={props.onRegistration}
            size='large'>
            <Segment padded="very">
                <h2>Rekisteröidy</h2>
                <div className='login-reset-text'>
                    Käytä rekisteröitymisessä samaa sähköpostia, kuin vanhassa palvelussa
                </div>
                {/* EMAIL */}
                <Form.Input
                    data-testid='email'
                    fluid
                    iconPosition='left'
                    required
                    {...props.email}
                    autoComplete="email"
                    className="login-spacer"
                />

                {/* FIRSTNAME */}
                <Form.Input
                    data-testid='given-name'
                    fluid
                    iconPosition='left'
                    required
                    {...props.firstname}
                    autoComplete="given-name"
                    className="login-spacer"
                />
                {/* LASTNAME */}
                <Form.Input
                    data-testid='family-name'
                    fluid
                    iconPosition='left'
                    required
                    {...props.lastname}
                    autoComplete="family-name"
                    className="login-spacer"
                />
                {/* PHONE */}
                <Form.Input
                    data-testid='tel'
                    fluid
                    iconPosition='left'
                    required
                    {...props.phone}
                    autoComplete="tel"
                    className="login-spacer"
                />

                <Button
                    className="login-btn login-spacer"
                    color='black'
                    disabled={registrationButtonIsDisabled}
                    fluid
                    loading={props.isLoading}
                    size='large'
                    type='submit'
                >
                    Rekisteröidy
                </Button>
                <Divider />
                <div className="login-links">
                    <div>
                        Takaisin <Link to="/login">sisäänkirjautumiseen</Link>
                    </div>
                </div>
            </Segment>
        </Form>
    )
}

RegistrationForm.propTypes = {
    email: PropTypes.object.isRequired,
    firstname: PropTypes.object.isRequired,
    isLoading: PropTypes.bool.isRequired,
    lastname: PropTypes.object.isRequired,
    onRegistration: PropTypes.func.isRequired,
    phone: PropTypes.object.isRequired,
}

export default RegistrationForm
