import { DateTime } from 'luxon'

export const handleOpenModal = (setOpen, bool) => () => {
    setOpen(bool)
}

export const handleChange = (setDate, setOpenSecond) => date => {
    setDate(date)
    setOpenSecond(false)
}

export const formatDate = date => DateTime.fromJSDate(date).toFormat('dd.MM.yyyy')
