import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { closeNotificationAction } from '../../reducers/notification-reducer/notification-reducer'
import { Icon } from 'semantic-ui-react'
import './top-notification.css'

const TopNotification = () => {
    const { message, notificationType, show } = useSelector(state => state.notification)
    const notificationResultClass = notificationType
    const dispatch = useDispatch()
    const handleCloseNotificationClick = () => dispatch(closeNotificationAction())

    if (!show)
        return null

    return <div
        className={`notification ${notificationResultClass}`}
        onClick={handleCloseNotificationClick}>
        {message}
        <Icon
            bordered
            className='notification-btn'
            name='close' />
    </div>
}

export default TopNotification
