import { convertToLocalDate } from 'src/utils/time'

export const handleChange = (setSelected, setDate, setActive, name) => date => {
    setSelected({ text: convertToLocalDate(date) }, name)
    setDate(date)
    setActive(false)
}

export const handleStateChange = (setActive, bool) => () => {
    setActive(bool)
}
