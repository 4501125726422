export const getDefaultImage = (checked, item) => checked.includes(item.id)

export const handleChange = (checked, add, remove, item) => () => {
    if (!checked.includes(item.id))
        add(item)
    else
        remove(item)
}

export const handleInputClear = setFilter => () => setFilter('')
export const handleFilterChange = setFilter => ({ target }) => setFilter(target.value)
