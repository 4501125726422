import React from 'react'
import { Button, Loader, Select } from 'semantic-ui-react'
import PropTypes from 'prop-types'
import { handleRemoveClick, handleDefaultValue } from './multi-report-dropdown.functions'

const ReportDropdown = props =>
    <div className='selector-grid-reports'>
        <div className='reports-selector-box reports-selector-header'>
            <h4>{props.entryItemType}:</h4>
        </div>
        <div className='reports-select'>
            {props.items === null ?
                <Select
                    icon={<Loader
                        active
                        size='small' />}
                    options={[]} /> :
                <Select
                    clearable
                    defaultValue={handleDefaultValue(props.entryItemType, props.items)}
                    index={props.index}
                    onChange={props.changeOptions}
                    options={props.items}
                    placeholder={handleDefaultValue(props.entryItemType, props.items)}
                    search
                    selection
                    title={props.entryItemType}
                />}
            <Button
                className='orange-white-btn'
                content='Poista'
                icon='delete'
                onClick={handleRemoveClick(props.remove, props.entryItemType)} />
        </div>
    </div>

ReportDropdown.defaultProps = { items: [] }

ReportDropdown.propTypes = {
    changeOptions: PropTypes.func.isRequired,
    entryItemType: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    items: PropTypes.array,
    remove: PropTypes.func.isRequired,
}

export default ReportDropdown
