import React, { useEffect, useState } from 'react'
import { Grid } from 'semantic-ui-react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useInputField } from '../../hooks'
import RegistrationForm from './user-registration-form/user-registration-form'
import { handleRegistration } from './user-registration.functions'

const nameHint = 'Ei saa olla tyhjä'
const userText = 'user'

const UserRegistration = () => {
    // Custom Hooks
    /* example: const name = useInputField(type, placeholder, icon, hinttext) */
    const email = useInputField('email', 'Sähköposti', 'mail', 'Sähköpostin tulee olla muotoa matti.meikalainen@domain.fi')
    const firstname = useInputField('text', 'Etunimi', userText, nameHint)
    const lastname = useInputField('text', 'Sukunimi', userText, nameHint)
    const phone = useInputField('tel', 'Puhelinnumero', 'phone', 'Puhelinnumeron tulee olla kansainvälisessä muodossa +358123123123')
    // Router
    const navigate = useNavigate()
    // Redux
    const { user } = useSelector(state => state.userData)
    const dispatch = useDispatch()
    const [isLoading, setIsLoading] = useState(false)
    const [registrationSuccessful, setRegistrationSuccessful] = useState(false)

    useEffect(() => {
        if (user)
            navigate('/')
    }, [navigate, user])

    return (
        <div className="login-bg">
            <Grid
                style={{ height: '100vh' }}
                textAlign='center'
                verticalAlign='middle'>
                {registrationSuccessful ?
                    <Grid.Column style={{ maxWidth: 450 }}>
                        <h1>Rekisteröityminen onnistui. Aseta salasana sähköpostiin saapuvan linkin kautta.</h1>
                    </Grid.Column> :
                    <Grid.Column style={{ maxWidth: 450 }}>
                        <RegistrationForm
                            email={email}
                            firstname={firstname}
                            isLoading={isLoading}
                            lastname={lastname}
                            onRegistration={handleRegistration({
                                setIsLoading,
                                email,
                                firstname,
                                lastname,
                                phone,
                                dispatch,
                                setRegistrationSuccessful,
                                navigate,
                            })}
                            phone={phone}
                        />
                    </Grid.Column>
                }
            </Grid>
        </div>
    )
}

export default UserRegistration
