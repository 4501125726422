import React from 'react'
import { useDispatch } from 'react-redux'
import { Modal, Dimmer, Icon } from 'semantic-ui-react'
import { handleLogoutClick } from './logout-modal.functions'
import logo from '../../assets/images/logo-444444.png'
import PropTypes from 'prop-types'
import './logout-modal.css'

const LogoutModal = props => {
    const dispatch = useDispatch()

    const getModalTrigger = () => {
        if (props.isMobile) {
            return <div
                className='menu-item mobile-screen'
                onClick={handleLogoutClick(dispatch)}>
                Kirjaudu ulos
            </div>
        }

        return <div
            className='user-div hover hide-in-mobile'
            onClick={handleLogoutClick(dispatch)}>
            <Icon name='log out' />
        </div>
    }

    return (
        <Modal
            className='user-logout-modal'
            dimmer='inverted'
            trigger={getModalTrigger()}>
            <Dimmer
                active
                page />
            <Modal.Content className='user-logout-content'>
                Odota hetki, sinua kirjataan ulos
            </Modal.Content>
            <img
                alt='Maranet logo'
                className='logout-logo'
                src={logo} />
        </Modal>
    )
}

LogoutModal.propTypes = { isMobile: PropTypes.bool.isRequired }

export default LogoutModal
