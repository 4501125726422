import React, { useState } from 'react'
import { Loader, Label, Transition } from 'semantic-ui-react'
import { lambdaCall } from '../../services/aws-lambda-service/aws-lambda-service'
import { awsApiEndpoints } from '../../utils/aws-api-endpoints'
import CheckboxBase from '../checkbox-base/checkbox-base'
import { loginAction } from '../../reducers/login-reducer/login-reducer'
import { logout } from '../../utils/redux-dispatch-configurations'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'

const timeOutMs = 5000

const CheckboxWithLoader = props => {
    const [pending, setPending] = useState(false)
    const [isChecked, setIsChecked] = useState(props.isChecked)
    const [failure, setFailure] = useState(false)
    const [animation, setAnimation] = useState(null)
    const [duration, setDuration] = useState(null)
    const [visibility, setVisibility] = useState(false)
    const dispatch = useDispatch()

    const click = async () => {
        setPending(true)

        const errorNotification = setTimeout(() => {
            setIsChecked(props.isChecked)
            setPending(false)
            setFailure(true)
            setAnimation('shake')
            setDuration(500)
            setVisibility(true)
        }, timeOutMs)

        const errorNotificationRemove = setTimeout(() => setFailure(false), timeOutMs + 2500)

        const errorNotificationFade = setTimeout(() => {
            setAnimation('fade')
            setDuration(3000)
            setVisibility(false)
        }, timeOutMs + 1000)

        const newBilledValue = !isChecked ? 'yes' : 'no'

        try {
            const result = await lambdaCall({
                body: {
                    billing: true,
                    id: props.orderId,
                    billed: newBilledValue,
                },
                endpoint: awsApiEndpoints.editOrder,
                timeoutMs: timeOutMs,
            })

            if (result.status === 200 && result.data.result === true) {
                setIsChecked(!isChecked)
                clearTimeout(errorNotification, errorNotificationRemove, errorNotificationFade)
            }

            setPending(false)
        } catch (error) {
            error.message === 'Token expired' && dispatch(loginAction({}, logout))
        }
    }

    const removeErrorClick = () => setFailure(false)

    const returnFunction = () => {
        if (failure && !pending) {
            return <div onClick={removeErrorClick}>
                <Transition
                    animation={animation}
                    duration={duration}
                    visible={visibility}>
                    <Label
                        color='red'
                        size='small'
                        type='fail'>VIRHE</Label>
                </Transition>
            </div>
        }

        if (!pending) {
            return <CheckboxBase
                isChecked={isChecked}
                onClickHandler={click}
            />
        }

        return <Loader
            active={pending}
            inline='centered'
            size='medium' />
    }

    return (
        <>
            {returnFunction()}
        </>
    )
}

CheckboxWithLoader.defaultProps = { isChecked: false }

CheckboxWithLoader.propTypes = {
    isChecked: PropTypes.bool,
    orderId: PropTypes.number.isRequired,
}

export default CheckboxWithLoader
