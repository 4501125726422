import { loginAction } from '../../reducers/login-reducer/login-reducer'
import { logout, acceptEula } from '../../utils/redux-dispatch-configurations'
import { errorAction, expiredTokenAction } from '../../reducers/notification-reducer/notification-reducer'
import { personalInformationAction } from 'src/reducers/personal-information-reducer/personal-information-reducer'

const throwError = (error, dispatch) => {
    if (error.message === 'Token expired') {
        dispatch(loginAction({}, logout))
        dispatch(expiredTokenAction())
    } else {
        dispatch(errorAction())
    }
}

const handleAcceptEulaClickAsync = async (eula, dispatch, navigate) => {
    try {
        await dispatch(personalInformationAction({ version: eula.currentEulaVersion }, acceptEula)) && navigate('/')
    } catch (error) {
        throwError(error, dispatch)
    }
}

export const handleAcceptEulaClick = (eula, dispatch, navigate) => () => {
    handleAcceptEulaClickAsync(eula, dispatch, navigate).catch(console.error)
}

export const handleCancelClick = (dispatch, navigate) => () => {
    dispatch(loginAction({}, logout))
    navigate('/')
}

export const handleCheckboxClick = (setChecked, checked) => () => {
    setChecked(!checked)
}
