import React, { useEffect, useState } from 'react'
import { Loader } from 'semantic-ui-react'
import { useDispatch, useSelector } from 'react-redux'
import { ordersAction } from '../../reducers/orders-reducer/orders-reducer'
import TableWithModal from '../../components/table-with-modal/table-with-modal'
import EditModal from '../../components/edit-modal/edit-modal'
import { hasAccess } from '../../utils/route-access'
import { ifTrueThenValue } from '../../utils/helper-functions'
import { setNewTableColumns } from '../../utils/columns'
import { successAction } from '../../reducers/notification-reducer/notification-reducer'
import { getOrders } from '../../utils/redux-dispatch-configurations'
import { setNewLimitStart, getMoreOrders, handleSelectClick } from './worklog.functions'
import PropTypes from 'prop-types'
import './worklog.css'

const limit = 50
const worklogSelectedText = 'worklog-selected'
const currentPage = 'orders'
const worklogTabClassName = 'worklog-tab'

const Worklog = props => {
    const [tableColumns, setTableColumns] = useState(setNewTableColumns(currentPage))
    const [selected, setSelected] = useState(1)
    const { data, dataIsLoading, infiniteScrollingIsLoading } = useSelector(state => state.orders)
    const [isOrderAssignment, setIsOrderAssignment] = useState(false)
    const [limitStart, setLimitStart] = useState(0)
    const [workerOrUser, setWorkerOrUser] = useState('')
    const dispatch = useDispatch()
    const { handleOrderButtonAction, orderButtonAction, turnJobToOrder, userRoles } = props
    const [selectedRow, setSelectedRow] = useState()

    const selectClickHandlerParams = {
        setSelected,
        setLimitStart,
        dispatch,
        setIsOrderAssignment,
        setWorkerOrUser,
        setTableColumns,
    }

    useEffect(() => {
        if (orderButtonAction) {
            dispatch(successAction(orderButtonAction))
            handleOrderButtonAction(null)

            return
        }

        dispatch(ordersAction(
            {
                limit: limit,
                limit_start: 0,
                orderassignment: false,
                worker_or_user_string: 'userExists',
            },
            getOrders,
        ))

        setWorkerOrUser('userExists')
        setLimitStart(limit)
    }, [dispatch, handleOrderButtonAction, orderButtonAction])

    return (
        <>
            <h2>Tarkastele toimintalokia</h2>
            <div className='worklog-tab-box '>
                <div
                    className={`${worklogTabClassName} ${ifTrueThenValue([selected === 1], worklogSelectedText)}`}
                    onClick={handleSelectClick(1, selectClickHandlerParams)}
                >
                    Omat kirjaukset
                </div>
                {ifTrueThenValue(
                    [hasAccess('kaikki-kirjaukset', userRoles)],
                    <div
                        className={`${worklogTabClassName} ${ifTrueThenValue([selected === 2], worklogSelectedText)}`}
                        onClick={handleSelectClick(2, selectClickHandlerParams)}>
                        Kaikki kirjaukset
                    </div>,
                )}
                <div
                    className={`${worklogTabClassName} ${ifTrueThenValue([selected === 3], worklogSelectedText)}`}
                    onClick={handleSelectClick(3, selectClickHandlerParams)}
                >
                    Omat työt
                </div>
                <div
                    className={`${worklogTabClassName} ${ifTrueThenValue([selected === 4], worklogSelectedText)}`}
                    onClick={handleSelectClick(4, selectClickHandlerParams)}
                >
                    Vapaat työt
                </div>
                {ifTrueThenValue(
                    [hasAccess('omat-työksiannot', userRoles)],
                    <div
                        className={`${worklogTabClassName} ${ifTrueThenValue([selected === 5], worklogSelectedText)}`}
                        onClick={handleSelectClick(5, selectClickHandlerParams)}
                    >
                        Omat työksiannot
                    </div>,
                )}
                {ifTrueThenValue(
                    [hasAccess('kaikki-työksiannot', userRoles)],
                    <div
                        className={`${worklogTabClassName} ${ifTrueThenValue([selected === 6], worklogSelectedText)}`}
                        onClick={handleSelectClick(6, selectClickHandlerParams)}
                    >
                        Kaikki työksiannot
                    </div>,
                )}
            </div>
            {dataIsLoading ?
                <Loader
                    active={dataIsLoading}
                    className='content-loader'
                    inline='centered' /> :
                <TableWithModal
                    columns={tableColumns}
                    currentPage={currentPage}
                    getMoreResults={getMoreOrders(
                        infiniteScrollingIsLoading,
                        dispatch,
                        limitStart,
                        isOrderAssignment,
                        workerOrUser,
                    )}
                    items={data}
                    key={selected}
                    limit={limit}
                    limitStart={limitStart}
                    setNewLimitStart={setNewLimitStart(setLimitStart, limitStart)}
                    setSelectedRow={setSelectedRow}
                />}

            {selectedRow ?
                <EditModal
                    currentPage={currentPage}
                    isJob={(selected === 3 || selected === 4)}
                    isOrderAssignment={isOrderAssignment}
                    item={selectedRow}
                    setNewLimitStart={setNewLimitStart(setLimitStart, limitStart)}
                    setSelectedRow={setSelectedRow}
                    turnJobToOrder={turnJobToOrder}
                /> :
                null}

            <Loader
                active={infiniteScrollingIsLoading}
                className='content-loader'
                inline='centered' />
        </>
    )
}

Worklog.defaultProps = { orderButtonAction: null }

Worklog.propTypes = {
    handleOrderButtonAction: PropTypes.func.isRequired,
    orderButtonAction: PropTypes.string,
    turnJobToOrder: PropTypes.func.isRequired,
    userRoles: PropTypes.array.isRequired,
}

export default Worklog
