import React from 'react'
import { Input, Popup } from 'semantic-ui-react'
import PropTypes from 'prop-types'

const SelectorTextInput = props =>
    <Popup
        content={props.placeholderValueOnDisabledInput}
        data-testid='popup'
        position='bottom center'
        style={{ opacity: props.placeholderValueOnDisabledInput ? 1 : 0 }}
        trigger={
            <div>
                <Input
                    disabled={props.isDisabled}
                    fluid
                    focus
                    icon={props.showDeleteIcon ? props.showDeleteIcon : null}
                    onChange={props.onInputChange}
                    placeholder='Syötä käsin'
                    size="large"
                    value={props.placeholderValueOnDisabledInput}
                />
            </div>
        }
    />

SelectorTextInput.propTypes = {
    isDisabled: PropTypes.bool.isRequired,
    onInputChange: PropTypes.func.isRequired,
    placeholderValueOnDisabledInput: PropTypes.string.isRequired,
    showDeleteIcon: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.string,
        PropTypes.object,
    ]).isRequired,
}

export default SelectorTextInput
