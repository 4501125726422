import React, { useEffect } from 'react'
import PropTypes, { func } from 'prop-types'
import TimePicker from '../../time-picker/time-picker'
import {
    handleFirstAddOnClick,
    handleFirstSubtractOnClick,
    handleSecondAddOnClick,
    handleSecondSubtractOnClick,
} from './time-selector.functions'

const TimeSelector = props => {
    const {
        firstValue,
        secondValue,
        isInitialEditing,
        calculateDuration,
        setFirstValue,
        setSecondValue,
        keepWithinRange,
    } = props

    useEffect(() => {
        if (isInitialEditing)
            return

        keepWithinRange(firstValue, setFirstValue, 23)
        keepWithinRange(secondValue, setSecondValue, 59)
        calculateDuration()
    }, [firstValue, secondValue, isInitialEditing, calculateDuration, setFirstValue, setSecondValue, keepWithinRange])

    return (
        <TimePicker
            firstAddOnClickHandler={handleFirstAddOnClick(props.valueUp, props.name, firstValue, setFirstValue)}
            firstSubtractOnClickHandler={handleFirstSubtractOnClick(props.valueDown, props.name, firstValue, setFirstValue)}
            secondAddOnClickHandler={handleSecondAddOnClick(props.valueUp, props.name, secondValue, setSecondValue)}
            secondSubtractOnClickHandler={handleSecondSubtractOnClick(props.valueDown, props.name, secondValue, setSecondValue)}
            {...props}
        />
    )
}

TimeSelector.defaultProps = {
    isInitialEditing: false,
    setIsInitialEditing: func,
}

TimeSelector.propTypes = {
    calculateDuration: PropTypes.func.isRequired,
    firstValue: PropTypes.number.isRequired,
    isInitialEditing: PropTypes.bool,
    keepWithinRange: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    secondValue: PropTypes.number.isRequired,
    setFirstValue: PropTypes.func.isRequired,
    setIsInitialEditing: PropTypes.func,
    setSecondValue: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    valueDown: PropTypes.func.isRequired,
    valueUp: PropTypes.func.isRequired,
}

export default TimeSelector
