import React, { useEffect, useRef, useState } from 'react'
import { Button, Dimmer, Form, Loader, Input } from 'semantic-ui-react'
import { useForm } from '../../hooks'
import TableWithEdit from '../../components/table-with-edit/table-with-edit'
import { useDispatch, useSelector } from 'react-redux'
import TableWithCheckbox from '../../components/table-with-checkbox/table-with-checkbox'
import { groupAction } from '../../reducers/groups-reducer/groups-reducer'
import { userAction } from '../../reducers/users-reducer/users-reducer'
import { getGroups, getUsers } from '../../utils/redux-dispatch-configurations'
import {
    handleEditClick,
    handleDeleteClick,
    handleCancelClick,
    sendLink,
    handleSaveClick,
    deleteButtonIsDisabled,
    handleAddGroupClick,
    handleRemoveGroupClick,
    handleDisableOrEnableClick,
} from './users-menu.functions'
import storage from '../../utils/storage'

const levels = [
    { text: '1: Aliurakoitsija', value: '1' },
    { text: '2: Normaali käyttäjä', value: '2' },
    { text: '3: Esimies', value: '3' },
    { text: '4: Ylempi esimies', value: '4' },
    { text: '5: Laskutus', value: '5' },
    { text: '6: Pääkäyttäjä', value: '6' },
]

const initialValues = {
    email: '',
    firstname: '',
    lastname: '',
    phone_number: '',
    role: '',
}

const tableColumns = [
    {
        header: 'Käyttäjä',
        valueName: 'email',
        hideInMobile: false,
    },
]

const resetRequiredText = 'Tarvitaan salasanan nollaus'
const temporaryPasswordInUseText = 'Väliaikainen salasana käytössä'
const unconfirmedText = 'Ei vahvistettu'
const usersString = 'users'

const UsersMenu = () => {
    const dispatch = useDispatch()
    const { loading: groupsAreLoading, usergroups, defaultUsergroupId } = useSelector(state => state.groups)
    const { loading: usersAreLoading, users } = useSelector(state => state.users)
    const { values, formErrors, setValues, handleChange, clearFields } = useForm(initialValues)
    const [checkedGroups, setCheckedGroups] = useState([defaultUsergroupId])
    const [edit, setEdit] = useState(false)
    const inputRef = useRef()
    const [sendLinkPending, setSendLinkPending] = useState(false)
    const [disabled, setDisabled] = useState(false)
    const user = storage.loadUser()

    const buttonIsDisabled = Object.values(formErrors).some(x => x !== null) ||
        Object.values(values).some(x => x === '')

    useEffect(() => {
        !users && dispatch(userAction({}, getUsers))
        !usergroups && users && dispatch(groupAction({}, getGroups))
        defaultUsergroupId && setCheckedGroups([defaultUsergroupId])
    }, [defaultUsergroupId, dispatch, usergroups, users])

    return (
        <div className='main-content'>
            <h2>Hallinnoi käyttäjiä</h2>
            <Dimmer.Dimmable dimmed={usersAreLoading || groupsAreLoading}>
                <Dimmer
                    active={usersAreLoading || groupsAreLoading}
                    inverted
                >
                    <Loader />
                </Dimmer>
                <div className='add-values-container'>
                    <Form className='add-values-form'>
                        {edit ? <h3>Muokkaa käyttäjän tietoja</h3> : <h3>Lisää uusi käyttäjä</h3>}
                        <Form.Field
                            autoComplete="email"
                            control={Input}
                            error={formErrors.email}
                            input={{ ref: inputRef }}
                            label='Sähköposti:'
                            name='email'
                            onChange={handleChange}
                            placeholder='Sähköposti'
                            required
                            type='email'
                            value={values.email}
                        />
                        <Form.Field
                            autoComplete="given-name"
                            control={Input}
                            error={formErrors.firstname}
                            label='Etunimi:'
                            name='firstname'
                            onChange={handleChange}
                            placeholder='Etunimi'
                            required
                            type='text'
                            value={values.firstname}
                        />
                        <Form.Field
                            autoComplete="family-name"
                            control={Input}
                            error={formErrors.lastname}
                            label='Sukunimi:'
                            name='lastname'
                            onChange={handleChange}
                            placeholder='Sukunimi'
                            required
                            type='text'
                            value={values.lastname}
                        />
                        <Form.Field
                            autoComplete="tel"
                            control={Input}
                            error={formErrors.phone_number}
                            icon='phone'
                            label='Puhelinnumero:'
                            name='phone_number'
                            onChange={handleChange}
                            placeholder='Puhelinnumero'
                            required
                            type='tel'
                            value={values.phone_number}
                        />
                        <Form.Select
                            label='Käyttötaso:'
                            name='role'
                            onChange={handleChange}
                            options={levels}
                            placeholder='Valitse käyttötaso'
                            required
                            type='select'
                            value={values.role}
                        />
                        {edit ?
                            <Form.Field>
                                <Form.Group>
                                    <Form.Field label='Tila:' />
                                    <Form.Field
                                        content={values.status}
                                        id='users-menu-status-field'
                                    />
                                </Form.Group>
                                {(values.status === resetRequiredText ||
                                    values.status === unconfirmedText ||
                                    values.status === temporaryPasswordInUseText) &&
                                    <Form.Field>
                                        <Form.Field label='Salasanan vaihto tapahtuu erillisen sähköpostiin lähetettävän linkin kautta' />
                                        <Button
                                            className='orange-white-btn no-margin-left'
                                            content='Lähetä sähköposti'
                                            loading={sendLinkPending}
                                            onClick={sendLink(setSendLinkPending, values, dispatch)}
                                        />
                                    </Form.Field>
                                }
                            </Form.Field> :
                            null}
                        {edit ?
                            <Form.Field>
                                <Button
                                    className='orange-white-btn no-margin'
                                    content='Peruuta'
                                    id='users-menu-cancel-button'
                                    onClick={handleCancelClick(
                                        setEdit,
                                        clearFields,
                                        setCheckedGroups,
                                        defaultUsergroupId
                                    )} />
                                <Button
                                    className='orange-white-btn'
                                    content={disabled ? 'Aktivoi' : 'Deaktivoi'}
                                    disabled={deleteButtonIsDisabled(edit, user)}
                                    id='users-menu-disable-button'
                                    onClick={handleDisableOrEnableClick({
                                        dispatch,
                                        values,
                                        clearFields,
                                        setEdit,
                                        setCheckedGroups,
                                        defaultUsergroupId,
                                        disabled,
                                        checkedGroups,
                                    })} />
                                <Button
                                    className='orange-white-btn no-margin-left'
                                    content='Poista'
                                    disabled={deleteButtonIsDisabled(edit, user)}
                                    id='users-menu-delete-button'
                                    onClick={handleDeleteClick(
                                        dispatch,
                                        values,
                                        clearFields,
                                        setEdit,
                                        setCheckedGroups,
                                        defaultUsergroupId
                                    )} />
                                <Button
                                    className='orange-white-btn no-margin'
                                    content='Tallenna'
                                    disabled={buttonIsDisabled}
                                    id='users-menu-save-button'
                                    onClick={handleSaveClick({
                                        values,
                                        checkedGroups,
                                        edit,
                                        setEdit,
                                        dispatch,
                                        clearFields,
                                        setCheckedGroups,
                                        defaultUsergroupId,
                                        usergroups,
                                    })} />
                            </Form.Field> :
                            <Form.Field>
                                <Button
                                    className='orange-white-btn no-margin-left'
                                    content='Lisää uusi'
                                    disabled={buttonIsDisabled}
                                    id='users-menu-add-new-button'
                                    onClick={handleSaveClick({
                                        values,
                                        checkedGroups,
                                        edit,
                                        setEdit,
                                        dispatch,
                                        clearFields,
                                        setCheckedGroups,
                                        defaultUsergroupId,
                                        usergroups,
                                    })} />
                            </Form.Field>
                        }
                    </Form>
                    <div className='add-values-table scrollable-list'>
                        <TableWithEdit
                            columns={tableColumns}
                            currentPage={usersString}
                            edit={edit}
                            items={users}
                            onEdit={handleEditClick(setEdit, setCheckedGroups, setValues, inputRef, clearFields, setDisabled)}
                        />
                    </div>
                </div>
                <TableWithCheckbox
                    add={handleAddGroupClick(setCheckedGroups)}
                    checked={checkedGroups}
                    defaultGroupId={defaultUsergroupId}
                    items={usergroups}
                    remove={handleRemoveGroupClick(setCheckedGroups)}
                    title='Käyttäjäryhmät'
                />
            </Dimmer.Dimmable>
        </div>
    )
}

export default UsersMenu
