export const setActiveTab = (setSelected, setNavActive, match) => {
    setSelected(match.params.selection)
    setNavActive(false)
}

export const handleNavOpenState = (setNavActive, navActive) => () => setNavActive(!navActive)

export const handleNavigate = navigate => () => navigate('/kirjaukset')

export const handleModalVisibilityClick = (setIsVisible, bool) => () => {
    setIsVisible(bool)
}
