const initialState = {}
const SHOW_ERROR = 'SHOW_ERROR'
const SHOW_WARNING = 'SHOW_WARNING'
const SHOW_SUCCESS = 'SHOW_SUCCESS'
const HIDE = 'HIDE'
const cancel = 'cancel'
const RESET = 'RESET'

let timeoutFunction = null

const actionDispatch = (payload, actionType) =>
    async dispatch => {
        await dispatch({ type: RESET })

        dispatch({
            type: actionType,
            payload: payload,
        })

        if (payload === cancel) {
            clearTimeout(timeoutFunction)

            return
        }

        if (timeoutFunction)
            clearTimeout(timeoutFunction)

        timeoutFunction = setTimeout(() => {
            dispatch({ type: HIDE })
        }, 10000) // if time is changed, change in top-notification.css accordingly
    }

// REDUCERS
export const notificationReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case SHOW_ERROR:
            return { message: action.payload, notificationType: 'failure', show: true }
        case SHOW_WARNING:
            return { message: action.payload, notificationType: 'warning', show: true }
        case SHOW_SUCCESS:
            return { message: action.payload, notificationType: 'success', show: true }
        case HIDE:
            return { show: false }
        case RESET:
            return { show: false }
        default:
            return state
    }
}

// ACTION CREATORS
/**
 * @param {String} text - error message
 * @example
 * dispatch(errorAction(String))
 */
export const errorAction = (text = 'Palvelussa tapahtui virhe') => actionDispatch(text, SHOW_ERROR)
export const expiredTokenAction = (text = 'Istunto on vanhentunut') => actionDispatch(text, SHOW_WARNING)

/**
 * @param {String} text - message
 * @example
 * dispatch(successAction(String))
 */
export const successAction = (text = 'OK') => actionDispatch(text, SHOW_SUCCESS)
export const closeNotificationAction = (text = cancel) => actionDispatch(text, HIDE)
