const typeObject = { header: 'Tyyppi', valueName: 'tyyppi', hideInMobile: false }
const deliveryObject = { header: 'Toimitus', valueName: 'toimituspäivä', hideInMobile: false }
const customerObject = { header: 'Asiakas', valueName: 'asiakas', hideInMobile: false }
const worksiteObject = { header: 'Työmaa', valueName: 'työmaa', hideInMobile: true }
const entryUserObject = { header: 'Kirjaaja', valueName: 'kirjaaja', hideInMobile: true }
const workerObject = { header: 'Työntekijä', valueName: 'työntekijä', hideInMobile: true }
const workAssignerObject = { header: 'Työksiantaja', valueName: 'kirjaaja', hideInMobile: true }
const attachmentObject = { header: 'Liite', hideInMobile: true }

const tableColumnsSchema = {
    orders: [attachmentObject, typeObject, customerObject, worksiteObject, entryUserObject, deliveryObject],
    ownAndAssignedOrderassignments: [attachmentObject, typeObject, customerObject, worksiteObject, deliveryObject],
    orderassignments: [attachmentObject, typeObject, customerObject, worksiteObject, workerObject, workAssignerObject, deliveryObject],
}

export const setNewTableColumns = type => tableColumnsSchema[type]
