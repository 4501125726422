import React from 'react'
import { createRoot } from 'react-dom/client'
import './index.css'
import App from './App/App'
import * as serviceWorker from './serviceWorker'
import store from './store/store'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'

createRoot(document.getElementById('root')).render(<Provider store={store}>
    <BrowserRouter>
        <App />
    </BrowserRouter>
</Provider>)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register()
