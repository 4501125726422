import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Grid, Header, Form, Segment, Button, Divider } from 'semantic-ui-react'
import { useDispatch } from 'react-redux'
import { useInputField } from '../../hooks'
import logo from '../../assets/images/logo-444444.png'
import { handleSaveClick } from './set-password.functions'

const SetPassword = () => {
    const password = useInputField(
        'password',
        'Uusi salasana',
        'lock',
        'Salasanan pitää olla vähintään 8 ja enintään 256 merkkiä pitkä,' +
        ' ja sen täytyy sisältää vähintään yhden kutakin seuraavista: pieni merkki, iso merkki, numero ja erikoismerkki'
    )

    const confirmPassword = useInputField('password', 'Vahvista salasana', 'lock')
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [passwordChanged, setPasswordChanged] = useState(false)
    const encodedString = window.location.href.split(`${process.env.REACT_APP_URL}/set-password/`)

    return (
        <div className="login-bg">
            <Grid
                data-testid='grid'
                style={{ height: '100vh' }}
                textAlign='center'
                verticalAlign='middle'>
                <Grid.Column
                    data-testid='grid-column'
                    style={{ maxWidth: 450 }}
                >
                    <Header>
                        <Header.Content>
                            <img
                                alt="Maranet logo"
                                className="login-logo"
                                src={logo} />
                        </Header.Content>
                    </Header>

                    <Form size='large'>
                        {
                            passwordChanged ?
                                <>
                                    <h1>Salasanasi on asetettu.</h1>
                                    <div className="center-group-text">
                                        <Link to="/login">Sisäänkirjautumissivu</Link>
                                    </div>
                                </> :
                                <Segment padded='very'>
                                    <h2>Salasanan asetus</h2>
                                    <>
                                        <Form.Input
                                            fluid
                                            iconPosition='left'
                                            required
                                            {...password}
                                            autoComplete='new-password'
                                            className="login-spacer"
                                        />
                                        <Form.Input
                                            autoComplete='new-password'
                                            fluid
                                            iconPosition='left'
                                            required
                                            {...confirmPassword}
                                            className="login-spacer"
                                        />
                                        <Button
                                            className="login-btn login-spacer"
                                            color='black'
                                            fluid
                                            loading={loading}
                                            onClick={handleSaveClick(
                                                password,
                                                confirmPassword,
                                                dispatch,
                                                setLoading,
                                                encodedString,
                                                setPasswordChanged,
                                                navigate,
                                            )}
                                            size='large'
                                            type="submit">
                                            Tallenna
                                        </Button>
                                    </>
                                    <Divider />
                                    <div className="login-links">
                                        <div>
                                            <Link to="/login">Sisäänkirjautumissivu</Link>
                                        </div>
                                    </div>
                                </Segment>
                        }
                    </Form>
                </Grid.Column>
            </Grid>
        </div>
    )
}

export default SetPassword
