import React, { useState, useRef } from 'react'
import { Button } from 'semantic-ui-react'
import { useDispatch } from 'react-redux'
import DateInterval from '../../../components/date-interval/date-interval'
import { DateTime } from 'luxon'
import CustomerInput from '../../../components/customer-input/customer-input'
import RadioButton from '../../../components/radio-button/radio-button'
import CheckboxBase from '../../../components/checkbox-base/checkbox-base'
import {
    handleKeyPress,
    handleCheckboxToggle,
    handleFromDateChange,
    handleToDateChange,
    handleSortOrderChange,
    createExcel,
} from './excel-report.functions'
import { startOfDateMonthAgo, endOfCurrentDate, sortFromAndToDates } from 'src/utils/time'

const ExcelReport = () => {
    const [isChecked, setIsChecked] = useState(false)
    const [fromDate, setFromDate] = useState(startOfDateMonthAgo)
    const [toDate, setToDate] = useState(endOfCurrentDate)
    const customerForm = useRef(null)
    const [sortOrder, setSortOrder] = useState('DESC')
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const sortOrderArray = [{ title: 'Laskeva', value: 'DESC', key: 1 }, { title: 'Nouseva', value: 'ASC', key: 2 }]
    const fileName = `Maranet-raportti-${DateTime.now().toFormat('yyyy-MM-dd')}.xlsx`
    const { firstDate, secondDate } = sortFromAndToDates(fromDate, toDate)

    return (
        <div>
            <div className='reports-description'>
                <h2>Tietojen vienti</h2>
                <div>
                    Tietojen vienti hakee kirjaukset valittujen tietojen perusteella ja lataa niistä Excel -dokumentin.
                </div>
            </div>
            <div className='reports-container'>
                <div className='selector-grid-reports'>
                    <div className='reports-selector-box reports-selector-header'>
                        <h4>Kaikki tiedot:</h4>
                    </div>
                    <div className='reports-checkbox-container'>
                        <CheckboxBase
                            isChecked={isChecked}
                            onClickHandler={handleCheckboxToggle(setIsChecked, isChecked)}
                        />
                    </div>
                </div>
                <div className='selector-grid-reports'>
                    <div className='reports-selector-box reports-selector-header'>
                        <h4>Aikaväli:</h4>
                    </div>
                    <DateInterval
                        firstDate={fromDate}
                        isDisabled={isChecked}
                        secondDate={toDate}
                        setFromDate={handleFromDateChange(setFromDate)}
                        setToDate={handleToDateChange(setToDate)}
                    />
                </div>
                <div className='selector-grid-reports'>
                    <div className='reports-selector-box reports-selector-header'>
                        <h4>Järjestys:</h4>
                    </div>
                    <RadioButton
                        handleValueChange={handleSortOrderChange(setSortOrder)}
                        value={sortOrder}
                        valueArray={sortOrderArray}
                    />
                </div>
                <div className='selector-grid-reports'>
                    <div className='reports-selector-box reports-selector-header'>
                        <h4>Asiakas:</h4>
                    </div>
                    <div className='reports-selector-box ui input '>
                        <form ref={customerForm}>
                            <CustomerInput
                                keyPressHandler={handleKeyPress(createExcel({
                                    setLoading,
                                    isChecked,
                                    customerForm,
                                    sortOrder,
                                    firstDate,
                                    secondDate,
                                    dispatch,
                                    fileName,
                                }))}
                            />
                        </form>
                    </div>
                </div>
            </div>
            <div className='selector-box-dl-btn'>
                <Button
                    className='orange-white-btn'
                    content='Lataa'
                    icon='download'
                    loading={loading}
                    onClick={createExcel({
                        setLoading,
                        isChecked,
                        customerForm,
                        sortOrder,
                        firstDate,
                        secondDate,
                        dispatch,
                        fileName,
                    })} />

            </div>
        </div>
    )
}

export default ExcelReport
