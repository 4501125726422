import React, { useState } from 'react'
import { Modal, Button, Checkbox } from 'semantic-ui-react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { handleAcceptEulaClick, handleCancelClick, handleCheckboxClick } from './eula-modal.functions'
import PropTypes from 'prop-types'

const EulaModal = props => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { loading } = useSelector(state => state.personalInformation)
    const [checked, setChecked] = useState(false)

    return (
        <Modal
            dimmer='inverted'
            open
        >
            <Modal.Header>Käyttöehdot: versio {props.eula.currentEulaVersion}</Modal.Header>
            <Modal.Content scrolling>
                {props.eula.eulaText}
            </Modal.Content>
            <Modal.Actions style={{ textAlign: 'center' }}>
                <Checkbox
                    data-testid='eulaCheckbox'
                    label='Sitoudun noudattamaan käyttöehtoja'
                    onChange={handleCheckboxClick(setChecked, checked)}
                    style={{ margin: '0.7em' }}
                />
                <Button
                    className='orange-white-btn'
                    content='Hyväksy'
                    data-testid={'eulaAcceptButton'}
                    disabled={!checked}
                    loading={loading}
                    onClick={handleAcceptEulaClick(props.eula, dispatch, navigate)}
                />
                <Button
                    className='orange-white-btn'
                    content='Peruuta'
                    onClick={handleCancelClick(dispatch, navigate)}
                />
            </Modal.Actions>
        </Modal>
    )
}

EulaModal.propTypes = { eula: PropTypes.object.isRequired }

export default EulaModal
