const accessoryCapitalFirstLetter = 'Lisävaruste'
const customerCapitalFirstLetter = 'Asiakas'
const pickupCapitalFirstLetter = 'Noutopaikka'
const productCapitalFirstLetter = 'Tarvike'
const productGroupCapitalFirstLetter = 'Tarvikeryhmä'
const vehicleCapitalFirstLetter = 'Kone'
const workerCapitalFirstLetter = 'Työntekijä'

export const dropdownOrderTypes = {
    allOrderTypes: '-- Kaikki tyypit --',
    transportationByLoad: 'Kuljetus',
    excavation: 'Kaivuutyö',
    transportationByTime: 'Tuntiajo',
    product: productCapitalFirstLetter,
    manualLabor: 'Henkilötyö',
}

export const dropdownBillingOptions = {
    unbilled: 'Näytä vain laskuttamattomat',
    billed: 'Näytä vain laskutetut',
    all: 'Näytä kaikki',
}

export const multiReportConditionOptions = [
    customerCapitalFirstLetter,
    'Asiakasryhmä',
    vehicleCapitalFirstLetter,
    'Kuormalaji',
    'Laskutus',
    accessoryCapitalFirstLetter,
    pickupCapitalFirstLetter,
    productCapitalFirstLetter,
    productGroupCapitalFirstLetter,
    'Työmaa',
    workerCapitalFirstLetter,
]

export const multiReportAdditionalFieldLabels = [
    'Kesto',
    'Kommentti',
    accessoryCapitalFirstLetter,
    pickupCapitalFirstLetter,
    workerCapitalFirstLetter,
    'Kuljetusmatka',
    'Aloitus- ja lopetusaika',
]

export const allOptions = '-- Kaikki --'
