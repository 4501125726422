const userKey = 'MARANET_LOGGED_USER'
const loadUser = () => JSON.parse(localStorage.getItem(userKey))
const logoutUser = () => localStorage.removeItem(userKey)
const saveUser = user => localStorage.setItem(userKey, JSON.stringify(user))

const storage = {
    loadUser,
    logoutUser,
    saveUser,
}

export default storage
