import React, { useEffect, useRef, useState } from 'react'
import { Image, Table } from 'semantic-ui-react'
import { dbToLocalDate } from '../../utils/time'
import CheckboxWithLoader from '../checkbox-with-loader/checkbox-with-loader'
import {
    handleObserver,
    isBilled,
    sortColumnClick,
    handleSorting,
    handleActiveRow,
} from './table-with-modal.functions'
import PropTypes from 'prop-types'
import fileAttachmentImage from '../../assets/images/file-attachment.png'

const byDate = 'byDate'
const orders = 'orders'
const ascending = 'ascending'
const fi = 'fi'
const attachment = 'Liite'

const options = {
    root: null,
    rootMargin: '20px',
    threshold: 1.0,
}

const TableWithModal = props => {
    const [sort, setSort] = useState({
        column: props.columns[props.columns.length - 1].valueName || null,
        direction: props.sortOrder === 'ASC' ?
            ascending :
            'descending',
    })

    const [isSorting, setIsSorting] = useState(false)
    const [page, setPage] = useState(false)
    const loader = useRef(null)
    const { getMoreResults, setNewLimitStart } = props

    let tableItems = props.items

    if (isSorting) {
        handleSorting(setIsSorting, false)

        tableItems = tableItems.sort((firstTableItem, secondTableItem) => {
            if (!firstTableItem[sort.column] || !secondTableItem[sort.column])
                return -1
            else if (sort.direction === ascending)
                return firstTableItem[sort.column].localeCompare(secondTableItem[sort.column], fi)

            return secondTableItem[sort.column].localeCompare(firstTableItem[sort.column], fi)
        })
    }

    useEffect(() => {
        const observer = new IntersectionObserver(handleObserver(setPage), options)

        loader.current && observer.observe(loader.current)
    }, [])

    useEffect(() => {
        if (!page)
            return

        if ((props.reportType === byDate || props.currentPage === orders) && props.limitStart === tableItems.length) {
            if (props.limitStart + props.limit >= tableItems.length)
                getMoreResults(props.limit)

            setNewLimitStart(props.limit)
            setPage(false)
        }
    }, [page, props.currentPage, props.limit, props.limitStart, setNewLimitStart, props.reportType, tableItems.length, getMoreResults])

    return (
        <div>
            <Table
                celled
                data-testid='table-with-modal'
                fixed
                selectable
                sortable
                unstackable>
                <Table.Header>
                    <Table.Row>
                        {props.columns.map((column, i) => {
                            let width = i === props.columns.length - 1 || i === props.columns.length - 2 ? 4 : 3

                            if (column.header === 'Asiakas')
                                width = 6

                            if (column.header === '€' || column.header === attachment)
                                width = 2

                            const sortIcon = sort.column === column.valueName ? sort.direction : null

                            return (
                                <Table.HeaderCell
                                    className={column.hideInMobile ? 'hide-in-mobile' : null}
                                    key={column.header}
                                    onClick={sortColumnClick(column, sort, setSort, setIsSorting)}
                                    sorted={sortIcon}
                                    textAlign='center'
                                    width={width}
                                >
                                    {column.header}
                                </Table.HeaderCell>
                            )
                        })}
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {tableItems.slice(0, props.reportType === 'amount' ? props.limitForOptionAmount : undefined).map(item =>
                        <Table.Row
                            key={item.kirjausnro}
                            onClick={handleActiveRow(props.setSelectedRow, item)}
                        >
                            {props.columns.map(column =>
                                <Table.Cell
                                    className={column.hideInMobile ? 'hide-in-mobile-cut-text' : 'cut-text'}
                                    key={column.header}
                                    onClick={column.header === '€' ? e => e.stopPropagation() : null}>
                                    {column.header === '€' ?
                                        <CheckboxWithLoader
                                            isChecked={isBilled(item.laskutettu)}
                                            orderId={item.tilausnro}
                                        /> :
                                        null}
                                    {column.header === attachment && item.orderAttachmentsCount > 0 ?
                                        <Image
                                            centered
                                            size='mini'
                                            src={fileAttachmentImage}
                                        /> :
                                        null}
                                    {column.header === 'Toimitus' ? dbToLocalDate(item[column.valueName]) : item[column.valueName]}
                                </Table.Cell>)}
                        </Table.Row>)}
                </Table.Body>
            </Table>

            <div ref={loader} />
        </div>
    )
}

TableWithModal.defaultProps = {
    currentPage: null,
    limitForOptionAmount: null,
    reportType: null,
    sortOrder: null,
}

TableWithModal.propTypes = {
    columns: PropTypes.array.isRequired,
    currentPage: PropTypes.string,
    getMoreResults: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.bool,
    ]).isRequired,
    items: PropTypes.array.isRequired,
    limit: PropTypes.number.isRequired,
    limitForOptionAmount: PropTypes.number,
    limitStart: PropTypes.number.isRequired,
    reportType: PropTypes.string,
    setNewLimitStart: PropTypes.func.isRequired,
    setSelectedRow: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.bool,
    ]).isRequired,
    sortOrder: PropTypes.string,
}

export default TableWithModal
