import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Button, Form, Input } from 'semantic-ui-react'
import TableWithEdit from '../table-with-edit/table-with-edit'
import { useForm } from '../../hooks'
import PropTypes from 'prop-types'
import {
    handleEditChange,
    handleCancelClick,
    handleDeleteClick,
    handleSaveClick,
    fieldShouldBeDisabled,
    getOptionsForSelect,
    getPlaceholderForSelect,
    handleDefaultCustomerGroupChange,
    handleFilterValue,
    handleClearBtn,
} from './entry-item-page.functions'

const EntryItemPage = props => {
    const initialValues = props.inputFields.reduce((accumulated, current) => ({ ...accumulated, [current.valueName]: '' }), {})
    const { values, setValues, handleChange, clearFields } = useForm(initialValues)
    const [edit, setEdit] = useState(false)
    const dispatch = useDispatch()
    const [defaultGroup, setDefaultGroup] = useState(true)
    const { tableItems, defaultCustomerGroupId } = props

    useEffect(() => {
        if (tableItems !== 'customers')
            return

        if (defaultGroup) {
            setDefaultGroup(false)
            handleDefaultCustomerGroupChange(setValues, defaultCustomerGroupId)
        }
    }, [defaultGroup, defaultCustomerGroupId, setValues, tableItems])

    return (
        <>
            <h2>Hallinnoi kirjaustietoja</h2>
            <div className='add-values-container'>
                <Form className='add-values-form'>
                    {edit ? <h3>Muokkaa tietoja</h3> : <h3>{props.title}</h3>}
                    {props.inputFields.map(inputField => {
                        switch (inputField.type) {
                            case 'text':
                                return (
                                    <Form.Field
                                        control={Input}
                                        key={inputField.valueName}
                                        label={inputField.label}
                                        name={inputField.valueName}
                                        onChange={handleChange}
                                        placeholder={inputField.placeholder}
                                        required={inputField.required}
                                        value={values[inputField.valueName] ? values[inputField.valueName] : ''}
                                    />)
                            case 'select':
                                return (
                                    <Form.Select
                                        clearable={handleClearBtn(inputField.valueName, tableItems)}
                                        data-testid={inputField.valueName}
                                        disabled={fieldShouldBeDisabled(inputField, values)}
                                        fluid
                                        key={inputField.valueName}
                                        label={inputField.label}
                                        name={inputField.valueName}
                                        onChange={handleChange}
                                        options={getOptionsForSelect({ inputField, props, values })}
                                        placeholder={getPlaceholderForSelect({ inputField, props, values })}
                                        required={inputField.required}
                                        value={values[inputField.valueName]}
                                    />)
                            default:
                                return <div key={inputField.valueName}>{inputField.valueName}</div>
                        }
                    })}
                    {edit ?
                        <Form.Field>
                            <Button
                                className='orange-white-btn no-margin'
                                content='Peruuta'
                                id='entry-items-cancel-button'
                                onClick={handleCancelClick(setEdit, clearFields, setDefaultGroup)} />
                            <Button
                                className='orange-white-btn'
                                content='Poista'
                                id='entry-items-delete-button'
                                onClick={handleDeleteClick(edit, props, dispatch)} />
                            <Button
                                className='orange-white-btn no-margin-left'
                                content='Tallenna'
                                id='entry-items-save-button'
                                onClick={handleSaveClick(values, props, edit, dispatch)} />
                        </Form.Field> :
                        <Form.Field>
                            <Button
                                className='orange-white-btn no-margin'
                                content='Lisää uusi'
                                id='entry-items-add-new-button'
                                onClick={handleSaveClick(values, props, edit, dispatch)} />
                        </Form.Field>
                    }
                </Form>
                <div className='scrollable-list'>
                    <TableWithEdit
                        columns={props.tableColumns}
                        edit={edit}
                        filterValue={handleFilterValue(values, tableItems)}
                        items={props.data[props.tableItems]}
                        onEdit={handleEditChange(setEdit, setValues)}
                        tableName={props.tableItems} />
                </div>
            </div>
        </>
    )
}

EntryItemPage.defaultProps = {
    data: null,
    defaultCustomerGroupId: null,
}

EntryItemPage.propTypes = {
    data: PropTypes.object,
    defaultCustomerGroupId: PropTypes.number,
    inputFields: PropTypes.array.isRequired,
    tableColumns: PropTypes.array.isRequired,
    tableItems: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
}

export default EntryItemPage
