import React, { useState } from 'react'
import { Search, Segment } from 'semantic-ui-react'
import IconButton from '../../../icon-button/icon-button'
import { handleSelectClick, handleSearchInputChange } from './list-with-search.functions'
import PropTypes from 'prop-types'
import './list-with-search.css'

const ListWithSearch = props => {
    const [filter, setFilter] = useState('')
    const filtered = props.items.filter(item => item.text.toLowerCase().includes(filter.toLowerCase()))

    return (
        <Segment className='column-flex selector-segment'>
            <h4 className='search-list-header'>Valitse {props.title}</h4>
            <Search
                className='search-list-header'
                onSearchChange={handleSearchInputChange(setFilter)}
                showNoResults={false}
                size='mini'
                value={filter}
            />
            <div className='vertical-padding search-list-results'>
                {filtered.length !== 0 ?
                    filtered.map(item => <IconButton
                        className='clear-orange-btn'
                        icon='plus'
                        isMobile={false}
                        key={item.id}
                        onClick={handleSelectClick(item, props.setSelected, setFilter)}
                        text={item.text}
                    />) :
                    <div>Ei tuloksia</div>}
            </div>
        </Segment>
    )
}

ListWithSearch.defaultProps = { items: [] }

ListWithSearch.propTypes = {
    items: PropTypes.array,
    setSelected: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
}

export default ListWithSearch
