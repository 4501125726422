import { errorAction, successAction, expiredTokenAction } from '../notification-reducer/notification-reducer'
import { lambdaCall } from '../../services/aws-lambda-service/aws-lambda-service'
import { loginAction } from '../login-reducer/login-reducer'
import { logout } from '../../utils/redux-dispatch-configurations'

const GET_REQ = 'GET-REPORT-REQUEST'
const GET_SUCCESS = 'GET-REPORT-SUCCESS'
const GET_FAILURE = 'GET-REPORT-FAILURE'
const GET_MORE_REQ = 'GET-MORE-ORDERS-REQUEST'
const GET_MORE_ORDERS = 'GET-MORE-ORDERS'
const DELETE_ITEM = 'DELETE-ITEM'
const RESET_STATE = 'RESET-ARRAY'
const initialState = { report: [] }

export const reportsReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case GET_REQ:
            return { ...state, reportIsLoading: true }
        case GET_SUCCESS:
            return {
                ...state,
                reportIsLoading: false,
                statusCode: action.status,
                report: state.report.concat.apply(
                    [],
                    [
                        ...state.report,
                        action.payload.sqlQueryResults,
                    ],
                ),
            }
        case GET_FAILURE:
            return { ...state, reportIsLoading: false }
        case GET_MORE_REQ:
            return { ...state, infiniteScrollingIsLoading: true }
        case RESET_STATE:
            return initialState
        case GET_MORE_ORDERS:
            return {
                ...state,
                infiniteScrollingIsLoading: false,
                report: state.report.concat.apply([], [...state.report, action.payload.sqlQueryResults]),
            }
        case DELETE_ITEM:
            return {
                ...state,
                report: state.report.filter(item => item.tilausnro !== action.payload.id),
            }
        default:
            return state
    }
}

// ACTION CREATORS
export const reportAction = (body, config, successText) => async dispatch => {
    try {
        if (config.reset)
            dispatch({ type: RESET_STATE })

        if (config.request)
            dispatch({ type: config.request })

        const response = await lambdaCall({ body: body, endpoint: config.endpoint })

        dispatch({
            type: config.dispatchType,
            payload: response.data,
            status: response.status,
        })

        successText && dispatch(successAction(successText))
    } catch (error) {
        dispatch({ type: GET_FAILURE })
        dispatch(errorAction())

        if (error.message === 'Token expired') {
            dispatch(loginAction({}, logout))
            dispatch(expiredTokenAction())
        }
    }
}
