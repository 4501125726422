import React, { useCallback, useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import Worklog from '../worklog/worklog'
import { useNavigate, useMatch } from 'react-router-dom'
import { orderTypeSchemas } from '../../schemas/order-type-schemas'
import OrdersPage from '../../components/orders-page/orders-page'
import worklogImg from '../../assets/images/toimintaloki.png'
import transportationByLoadImg from '../../assets/images/kuljetus.png'
import excavationImg from '../../assets/images/kaivuu.png'
import transportationByTimeImg from '../../assets/images/ajo.png'
import productImg from '../../assets/images/tarvikkeet.png'
import manualLaborImg from '../../assets/images/henkilotyo.png'
import { changeSubPage } from './orders-menu.functions'

const OrdersMenu = () => {
    const worklogString = 'toimintaloki'
    const [active, setActive] = useState(worklogString)
    const { user } = useSelector(state => state.userData)
    const [orderButtonAction, setOrderButtonAction] = useState(null)
    const navigate = useNavigate()
    const match = useMatch('/kirjaukset/:active')
    const [jobWillBeConvertedToOrder, setJobWillBeConvertedToOrder] = useState(false)
    const handleOrderButtonAction = useCallback(value => setOrderButtonAction(value), [])
    const turnJobToOrder = value => setJobWillBeConvertedToOrder(value)
    const getTypeById = id => orderTypeSchemas.find(x => x.id === id).type

    let tab
    let content

    useEffect(() => {
        if (match && active !== match.params.active)
            setActive(match.params.active)
    }, [match, active])

    if (active === worklogString) {
        tab = <Worklog
            handleOrderButtonAction={handleOrderButtonAction}
            orderButtonAction={orderButtonAction}
            turnJobToOrder={turnJobToOrder}
            userRoles={user.roles}
        />
    } else {
        content = orderTypeSchemas.find(item => active === item.type)

        if (content) {
            tab = <OrdersPage
                additionalInfo={content.additionalInfo}
                additionalSelectors={content.additionalSelectors}
                handleOrderButtonAction={handleOrderButtonAction}
                jobWillBeConvertedToOrder={jobWillBeConvertedToOrder}
                key={active}
                mainInfo={content.mainInfo}
                mainSelectors={content.mainSelectors}
                orderAssignmentIsEnabled={content.orderAssignmentIsEnabled && ['3', '4', '6'].includes(user.roles[0])}
                title={content.title}
                turnJobToOrder={turnJobToOrder}
                typeId={content.id}
                user={user}
                workerChangeIsDisabled={!['3', '4', '6'].includes(user.roles[0])}
            />
        }
    }

    const getClassName = buttonString => active === buttonString ? 'main-item main-selected' : 'main-item hover'

    return (
        <div>
            <div className='main-row-with-icons'>
                <div
                    className={getClassName(worklogString)}
                    data-testid='worklog'
                    onClick={changeSubPage(setActive, navigate, worklogString)}>
                    <img
                        alt='worklog'
                        className='main-image'
                        src={worklogImg} />
                    Toimintaloki
                </div>
                <div
                    className={getClassName(getTypeById(1))}
                    data-testid='transportationByLoad'
                    onClick={changeSubPage(setActive, navigate, getTypeById(1))}>
                    <img
                        alt='transportationByLoad'
                        className='main-image'
                        src={transportationByLoadImg} />
                    Kuljetus
                </div>
                <div
                    className={getClassName(getTypeById(2))}
                    data-testid='excavation'
                    onClick={changeSubPage(setActive, navigate, getTypeById(2))}>
                    <img
                        alt='excavation'
                        className='main-image'
                        src={excavationImg} />
                    Kaivuutyö
                </div>
                <div
                    className={getClassName(getTypeById(3))}
                    data-testid='transportationByTime'
                    onClick={changeSubPage(setActive, navigate, getTypeById(3))}>
                    <img
                        alt='transportationByTime'
                        className='main-image'
                        src={transportationByTimeImg} />
                    Tuntiajo
                </div>
                <div
                    className={getClassName(getTypeById(4))}
                    data-testid='product'
                    onClick={changeSubPage(setActive, navigate, getTypeById(4))}>
                    <img
                        alt='product'
                        className='main-image'
                        src={productImg} />
                    Tarvikkeet
                </div>
                <div
                    className={getClassName(getTypeById(5))}
                    data-testid='manualLabor'
                    onClick={changeSubPage(setActive, navigate, getTypeById(5))}>
                    <img
                        alt='manualLabor'
                        className='main-image'
                        src={manualLaborImg} />
                    Henkilötyö
                </div>
            </div>
            <div className='main-content'>
                {tab}
            </div>
        </div>
    )
}

export default OrdersMenu
