import { DateTime } from 'luxon'

const day = 'day'

export const getDate = timestamp => dbToLocalDate(new Date(timestamp).toISOString())

export const getTime = timestamp => {
    let date = timestamp

    typeof date === 'string' && (date = new Date(date))

    const hours = `0${date.getHours()}`
    const minutes = `0${date.getMinutes()}`
    const seconds = `0${date.getSeconds()}`

    return `${hours.slice(-2)}:${minutes.slice(-2)}:${seconds.slice(-2)}`
}

export const getDateObject = timestamp => {
    let date = timestamp

    typeof date === 'string' && (date = new Date(date))

    return date
}

export const dbToLocalDate = date => DateTime.fromISO(date).toUTC().toFormat('dd.MM.yyyy')
export const dbToLocalDateTime = date => DateTime.fromISO(date).toUTC().toFormat('dd.MM.yyyy HH:mm:ss')

export const sortFromAndToDates = (fromDate, toDate) => {
    const firstDate = fromDate > toDate ?
        DateTime.fromJSDate(toDate).startOf(day).toJSDate() :
        DateTime.fromJSDate(fromDate).startOf(day).toJSDate()

    const secondDate = fromDate > toDate ?
        DateTime.fromJSDate(fromDate).endOf(day).toJSDate() :
        DateTime.fromJSDate(toDate).endOf(day).toJSDate()

    return { firstDate: firstDate, secondDate: secondDate }
}

export const startOfDateMonthAgo = DateTime.now().minus({ months: 1 }).startOf(day).toJSDate()
export const endOfCurrentDate = DateTime.now().endOf(day).toJSDate()
export const convertToLocalDate = date => DateTime.fromJSDate(date).toFormat('yyyy-MM-dd').toLocaleString()
