import { lambdaCall } from '../../services/aws-lambda-service/aws-lambda-service'
import { awsApiEndpoints } from '../../utils/aws-api-endpoints'
import { errorAction, successAction } from '../../reducers/notification-reducer/notification-reducer'

export const handleRegistration = ({
    setIsLoading,
    email,
    firstname,
    lastname,
    phone,
    dispatch,
    setRegistrationSuccessful,
    navigate,
}) => async e => {
    e.preventDefault()

    try {
        setIsLoading(true)

        await lambdaCall({
            body: {
                email: email.value,
                firstname: firstname.value,
                lastname: lastname.value,
                phone_number: phone.value,
            }, endpoint: awsApiEndpoints.registration,
        })

        dispatch(successAction('Sähköposti lähetetty salasanan asettamista varten'))

        setRegistrationSuccessful(true)

        setTimeout(() => {
            navigate('/login')
        }, 10000)
    } catch {
        dispatch(errorAction('Rekisteröityminen epäonnistui'))
    }

    setIsLoading(false)
}
