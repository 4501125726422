import React, { useEffect, useState } from 'react'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from './fonts'
import PropTypes from 'prop-types'

pdfMake.vfs = pdfFonts.pdfMake ? pdfFonts.pdfMake.vfs : pdfMake.vfs

const PdfEmbed = props => {
    const [b64, setB64] = useState()

    useEffect(() => {
        if (!props.json) {
            setB64(null)

            return
        }

        const pdfDocGenerator = pdfMake.createPdf(props.json)

        pdfDocGenerator.getBase64(data => {
            setB64(data)
        })
    }, [props.json])

    if (!b64)
        return null

    return (
        <embed
            className='pdf-report'
            height={800}
            id='pdf'
            src={`data:application/pdf;base64,${b64}`}
        />
    )
}

PdfEmbed.defaultProps = { json: null }

PdfEmbed.propTypes = { json: PropTypes.object }

export default PdfEmbed
