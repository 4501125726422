import React, { useEffect, useState, useRef } from 'react'
import { Input, Popup } from 'semantic-ui-react'
import PropTypes from 'prop-types'
import { handleInputChange } from './comment-selector.functions'
import { ifTrueThenValueElse, nullish } from 'src/utils/helper-functions'
import './comment-selector.css'

const selectorBox = 'selector-box'
const selectorBoxSelected = `${selectorBox} selector-selected`

const CommentSelector = props => {
    const [comment, setComment] = useState(nullish(props.selected?.text, ''))
    const isFirstRender = useRef(true)

    useEffect(() => {
        if (!isFirstRender.current)
            setComment('')
        else
            isFirstRender.current = false
    }, [props.isInitialValues])

    return (
        <div
            className={ifTrueThenValueElse([props.selected?.text?.length > 0], selectorBoxSelected, selectorBox)}
            data-testid='comment-box'
        >
            <div className='column-flex'>
                <h3>{props.title.toUpperCase()}</h3>
                <div
                    className='comment-selector'
                    data-testid='comment-input-box'
                >
                    <Popup
                        content={comment}
                        position='bottom center'
                        style={{ opacity: comment ? 1 : 0 }}
                        trigger={
                            <Input
                                className='comment-selector-input'
                                fluid
                                focus
                                onChange={handleInputChange(setComment, props.name, props.setSelected)}
                                placeholder='Lisää kommentti'
                                size='large'
                                value={comment}
                            />
                        }
                    />
                </div>
            </div>
        </div>
    )
}

CommentSelector.propTypes = {
    isInitialValues: PropTypes.bool.isRequired,
    name: PropTypes.string.isRequired,
    selected: PropTypes.object.isRequired,
    setSelected: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
}

export default CommentSelector
