import { useState } from 'react'

const { getFieldValidationError, errorMessages } = require('./hooks-helpers/hooks-helpers')

export const useInputField = (type, placeholder, icon, message) => {
    /**
     * Hook for input fields
     * ```
     * type = type of input
     *
     * placeholder = placeholder text
     *
     * icon = icon name from semantic library
     *
     * message = content for the hint
     * ```
     */
    const [value, setValue] = useState('')
    const [error, setError] = useState(false)

    const onChange = event => {
        setValue(event.target.value)
        validateField(event.target.value)
    }

    const onBlur = event => {
        validateField(event.target.value)
    }

    const validateField = input => {
        setError(getFieldValidationError(input, type) ? message : null)
    }

    return {
        type,
        value,
        onChange,
        onBlur,
        placeholder,
        icon,
        error,
    }
}

export const useForm = (initialValues = {}) => {
    const [values, setValues] = useState(initialValues)
    const [formErrors, setFormErrors] = useState({ email: null, firstname: null, lastname: null, phone_number: null })

    const handleErrorIfExists = target => {
        const errorMessage = errorMessages[target.type]
        const errorsCopy = { ...formErrors }

        errorsCopy[target.name] = getFieldValidationError(target.value, target.type) ? errorMessage : null
        setFormErrors(errorsCopy)
    }

    const handleChange = (event, select = undefined) => {
        const { name, value } = !select ? event.target : select

        if (select)
            handleErrorIfExists(event.target)

        if (name === 'group_id' && values.customer_id)
            setValues({ ...values, customer_id: '' })

        setValues(initial => ({ ...initial, [name]: value }))
    }

    const clearFields = () => {
        let fields = {}

        for (const name in values) {
            if (Object.prototype.hasOwnProperty.call(values, name) && !['id', 'cognitoId', 'status'].includes(name))
                fields = { ...fields, [name]: '' }
        }

        setFormErrors({ email: null, firstname: null, lastname: null, phone_number: null })
        setValues(fields)
    }

    return {
        values,
        formErrors,
        setValues,
        handleChange,
        clearFields,
        edited: JSON.stringify(initialValues) !== JSON.stringify(values),
    }
}

export const useOrderForm = (initialValues = {}) => {
    const [values, setValues] = useState(initialValues)

    const handleChange = (value, name) => {
        if (value) {
            if (!value.text)
                setValues(initial => ({ ...initial, [name]: { ...initial[name], unit_id: value.unit_id, unit_text: value.unit_text } }))
            else
                setValues(initial => ({ ...initial, [name]: value }))
        } else {
            setValues(initial => ({ ...initial, [name]: { text: null } }))
        }
    }

    return {
        values,
        setValues,
        handleChange,
    }
}
